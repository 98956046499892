import { QueryHookOptions, useQuery } from "@apollo/client";
import gql from "graphql-tag";

interface OTPStats {
  gamesPlayed: number;
  iconId: number;
  lp: number;
  regionId: string;
  riotTagLine: string;
  riotUserName: string;
  tier: string;
  rank: string;
  winRate: number;
}
export interface OTPStatsData {
  getChampionOtpStats: OTPStats[];
}

const GET_CHAMPION_OTP_STATS = gql`
  query getChampionOtpStats($championId: Int!) {
    getChampionOtpStats(championId: $championId) {
      gamesPlayed
      iconId
      lp
      regionId
      riotTagLine
      riotUserName
      tier
      rank
      winRate
    }
  }
`;
export function useGetChampionOTPStats(championId: number, queryParams?: QueryHookOptions) {
  return useQuery<OTPStatsData>(GET_CHAMPION_OTP_STATS, {
    variables: {
      championId: championId || -1,
    },
    ssr: false,
    ...queryParams,
  });
}

const GET_CHAMPION_OTP_STATS2 = gql`
  query getChampionOtpStats2($championId: Int!, $regionId: String!) {
    getChampionOtpStats2(championId: $championId, regionId: $regionId) {
      gamesPlayed
      iconId
      lp
      regionId
      riotTagLine
      riotUserName
      tier
      rank
      winRate
    }
  }
`;
export function useGetChampionOTPStats2(championId: number, regionId: number, queryParams?: QueryHookOptions) {
  return useQuery(GET_CHAMPION_OTP_STATS2, {
    variables: {
      championId,
      regionId,
    },
    ssr: false,
    ...queryParams,
  });
}
