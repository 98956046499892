import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGlobal } from "reactn";
import { MATCHUPS_OPTIONS } from "query_parameters/filter_options";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { numberWithCommas, getWinRateColorClassName, getWinRateColor } from "lib/general-helper";
import { getTierColor, getTierColorHex } from "lib/tierHelper";
import YellowAlert from "components/YellowAlert";

const blueGradient = [
  "rgba(50, 115, 250, 1)",
  "linear-gradient(0deg, rgba(50, 115, 250, 0.8), rgba(50, 115, 250, 0.8)), #FFFFFF",
  "linear-gradient(0deg, rgba(50, 115, 250, 0.6), rgba(50, 115, 250, 0.6)), #FFFFFF",
  "linear-gradient(0deg, rgba(50, 115, 250, 0.4), rgba(50, 115, 250, 0.4)), #FFFFFF",
  "linear-gradient(0deg, rgba(50, 115, 250, 0.2), rgba(50, 115, 250, 0.2)), #FFFFFF",
];
const redGradient = [
  "rgba(255, 78, 80, 1)",
  "linear-gradient(0deg, rgba(255, 78, 80, 0.8), rgba(255, 78, 80, 0.8)), #FFFFFF",
  "linear-gradient(0deg, rgba(255, 78, 80, 0.6), rgba(255, 78, 80, 0.6)), #FFFFFF",
  "linear-gradient(0deg, rgba(255, 78, 80, 0.4), rgba(255, 78, 80, 0.4)), #FFFFFF",
  "linear-gradient(0deg, rgba(255, 78, 80, 0.2), rgba(255, 78, 80, 0.2)), #FFFFFF",
];
const greenGradient = [
  "rgba(49, 219, 158, 1)",
  "linear-gradient(0deg, rgba(49, 219, 158, 0.8), rgba(49, 219, 158, 0.8)), #FFFFFF",
  "linear-gradient(0deg, rgba(49, 219, 158, 0.6), rgba(49, 219, 158, 0.6)), #FFFFFF",
  "linear-gradient(0deg, rgba(49, 219, 158, 0.4), rgba(49, 219, 158, 0.4)), #FFFFFF",
  "linear-gradient(0deg, rgba(49, 219, 158, 0.2), rgba(49, 219, 158, 0.2)), #FFFFFF",
];

const formatData = (data, championName, filters, listType) => {
  const currentRank = MATCHUPS_OPTIONS.rank.find((rank) => rank.value === filters.rank);
  const currentRegion = MATCHUPS_OPTIONS.region.find((region) => region.value === filters.region);
  const newData = data.map((x) => {
    const newChampion = { ...x };
    newChampion.win_rate = Math.round((100 - newChampion.win_rate) * 100) / 100;
    newChampion.gold_adv_15 = -1 * newChampion.gold_adv_15;
    return newChampion;
  });

  const snippet = {
    "best-win-rate": {
      header: `Best Picks vs ${championName}`,
      snippet: `These picks are strong against ${championName} at many stages of the game. Champs listed by highest win rate matchups vs ${championName} in ${currentRegion.label} ${currentRank.label}.`,
      data: [...newData]
        .sort((a, b) => parseFloat(b.win_rate) - parseFloat(a.win_rate))
        .map((x) => {
          const obj = { ...x };
          if (obj.win_rate >= 55) {
            obj.color = blueGradient[0];
          } else if (obj.win_rate >= 53) {
            obj.color = blueGradient[1];
          } else if (obj.win_rate >= 51.5) {
            obj.color = blueGradient[2];
          } else if (obj.win_rate >= 48.5) {
            obj.color = blueGradient[3];
          } else {
            obj.color = blueGradient[4];
          }
          return obj;
        }),
    },
    "worst-win-rate": {
      header: `Worst Picks vs ${championName}`,
      snippet: `These picks are weak against ${championName} at many stages of the game. Champs listed by lowest win rate matchups vs ${championName} in ${currentRegion.label} ${currentRank.label}.`,
      data: [...newData]
        .sort((a, b) => parseFloat(a.win_rate) - parseFloat(b.win_rate))
        .map((x) => {
          const obj = { ...x };
          if (obj.win_rate < 45) {
            obj.color = redGradient[0];
          } else if (obj.win_rate <= 47) {
            obj.color = redGradient[1];
          } else if (obj.win_rate <= 48.5) {
            obj.color = redGradient[2];
          } else if (obj.win_rate <= 50) {
            obj.color = redGradient[3];
          } else {
            obj.color = redGradient[4];
          }
          return obj;
        }),
    },
    "gold-diff": {
      header: `Best Lane Counters vs ${championName}`,
      snippet: `These picks counter ${championName} during early game laning phase. Highest gold differential at 15 (GD@15) vs ${championName} in ${currentRegion.label} ${currentRank.label}.`,
      data: [...newData]
        .sort((a, b) => parseFloat(b.gold_adv_15) - parseFloat(a.gold_adv_15))
        .map((x, index) => {
          const obj = { ...x };
          if (obj.gold_adv_15 <= 0) {
            obj.color = greenGradient[4];
          } else if (index <= 0) {
            obj.color = greenGradient[0];
          } else if (index <= 2) {
            obj.color = greenGradient[1];
          } else if (index <= 5) {
            obj.color = greenGradient[2];
          } else if (index <= 9) {
            obj.color = greenGradient[3];
          } else {
            obj.color = greenGradient[4];
          }
          return obj;
        }),
    },
  };

  return snippet[listType];
};

const calculatePercent = (value, data, listType) => {
  switch (listType) {
    case "best-win-rate":
    case "worst-win-rate":
      return value;
    case "gold-diff": {
      const base = value >= 0 ? data[0].gold_adv_15 : data[data.length - 1].gold_adv_15;
      if (value === base) return 100;
      const percent = Math.round((parseFloat(value) / parseFloat(base)) * 10000) / 100 / 2;
      return value > 0 ? 50 + percent : 50 - percent;
    }
    default:
      return value;
  }
};

const CountersListView = (props) => {
  const { getChampionName, getNormalizedChampionName, getChampionImgFromSprite } = getRiotAssetsContext();
  const [selfListCount, setSelfListCount] = useState(5);
  const [windowBreakpoint] = useGlobal("responsive");

  const { listCount, incrementListCount, championId, championName, filters, data, rankingStats, listType, snippetIcon } = props;

  const isMobile = windowBreakpoint === "MOBILE_SMALL" || windowBreakpoint === "MOBILE_MEDIUM";

  const formattedData = formatData(data, championName, filters, listType);
  const currentCount = isMobile ? selfListCount : listCount;
  const incrementCount = (totalChampCount) => {
    isMobile ? setSelfListCount(totalChampCount) : incrementListCount(totalChampCount);
  };
  const showWarning =
    rankingStats &&
    ((rankingStats.pick_rate && rankingStats.pick_rate < 1) || (rankingStats.matches && rankingStats.matches < 100));

  return (
    <div className="counters-column">
      <div className="top-half">
        <div className="free-header">
          <div>{formattedData.header}</div>
          <div className="counter-warning">{showWarning && <YellowAlert id={"win_rate"} label={"Low sample size"} />}</div>
        </div>
        <div className="text-snippet">
          <div className="snippet-icon">
            <img src={snippetIcon} />
          </div>
          {formattedData.snippet}
        </div>
      </div>
      <div className={`counters-list ${listType}`}>
        {formattedData.data.slice(0, currentCount).map((champion, index) => {
          const { champion_id, win_rate, gold_adv_15, matches, color } = champion;

          const championName = getChampionName(champion_id);
          const normalizedChampionName = getNormalizedChampionName(champion_id);
          const percent = calculatePercent(listType === "gold-diff" ? gold_adv_15 : win_rate, formattedData.data, listType);
          return (
            <Link
              key={champion_id}
              className={`counter-list-card ${listType}`}
              to={`/lol/champions/${normalizedChampionName}/build`}
            >
              <div className="col-1 champion-image">{getChampionImgFromSprite(champion_id, { size: 36 })}</div>
              <div className="col-2">
                <div className="champion-name">{championName}</div>
                <div className="win-rate-bar-container">
                  <div
                    className="win-rate-bar"
                    style={{
                      width: `${percent}%`,
                      background: color,
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="win-rate">
                  {listType === "gold-diff" ? `${gold_adv_15 > 0 ? "+" : ""}${gold_adv_15} GD15` : `${win_rate}% WR`}
                </div>
                <div className="total-games">{numberWithCommas(matches)} games</div>
              </div>
            </Link>
          );
        })}
        {isMobile && selfListCount < formattedData.data.length && (
          <div className="view-more-btn" onClick={() => incrementCount(formattedData.data.length)}>
            See More Champions
          </div>
        )}
        {!isMobile && listCount < formattedData.data.length && (
          <div className="view-more-btn" onClick={() => incrementCount(formattedData.data.length)}>
            See More Champions
          </div>
        )}
      </div>
    </div>
  );
};

export default CountersListView;
