import React from "react";
import { useGlobal } from "reactn";
import classnames from "classnames";
import { useLoginState, useFavorites } from "lib/hooks/userHooks";
import { ReactComponent as Star } from "svg/star.svg";

const FavoriteChampionButton = (props) => {
  const [clientFavorites, setClientFavorites] = useGlobal("clientChampionFavorites");
  const [updateFavorites, { data }] = useFavorites();
  const isLoggedIn = useLoginState();
  const { championId } = props;

  const checkIfFavorite = (currentChampionId) => {
    // Determine whether or not this champion is in the above list.
    if (clientFavorites) {
      for (const element of clientFavorites) {
        if (element.championId == currentChampionId) {
          return true;
        }
      }
    }

    return false;
  };

  const sendRequest = (isFavorite) => {
    let currentFavoriteChampions = clientFavorites;
    let currentChampionId = Number(championId);

    // Determine what the future favorite champions would look like, in either state.
    let favoriteChampions = [];
    if (isFavorite) {
      currentFavoriteChampions.forEach((element) => {
        if (element.championId !== currentChampionId) {
          favoriteChampions.push({ championId: Number(element.championId) });
        }
      });
    } else {
      if (currentFavoriteChampions) {
        favoriteChampions = currentFavoriteChampions.map((element) => {
          return { championId: Number(element.championId) };
        });
      }
      favoriteChampions.push({ championId: currentChampionId });
    }

    updateFavorites("champions", favoriteChampions);
  };

  if (!isLoggedIn || !clientFavorites) {
    return null;
  }

  const isFavorite = checkIfFavorite(Number(championId));
  const buttonClassName = classnames("favorite-button", {
    "favorite-button_selected": isFavorite,
  });

  return (
    <div className={buttonClassName} onClick={() => sendRequest(isFavorite)}>
      <Star className="favorite-button_icon" />
    </div>
  );
};

export default FavoriteChampionButton;
