import React from "react";
import classnames from "classnames";
import { useGlobal } from "reactn";
import { ChampionSkill } from "@outplayed/tooltips";

const ChampionSkillWithLabel = (props) => {
  const [language] = useGlobal("language");
  // same props as ChampionSkills
  const {
    label,
    labelPosition, // bottomRight, bottomCenter
    ...tooltipProps
  } = props;

  const skillLabelClassNames = classnames("skill-label", {
    "bottom-right": labelPosition === "bottomRight",
    "bottom-center": labelPosition === "bottomCenter",
  });
  return (
    <div className="champion-skill-with-label">
      <ChampionSkill {...tooltipProps} language={language} webp />
      <div className={skillLabelClassNames}>{label}</div>
    </div>
  );
};

export default ChampionSkillWithLabel;
