import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { useQueryString } from "hooks/general-hooks";

import { PageNav } from "@ugg/shared/components/common/PageNav";
import ChampionsFacePage from "../ChampionsFacePage";
import ChampionLeaderboards from "./ChampionLeaderboards";
import { REGION_OPTIONS_ALL } from "./RegionOptions";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { RampLeftRail, RampRightRail } from "components/Ads/RampRails";
import MediaQuery from "components/MediaQuery";

const ChampionsPage = (props) => {
  const { getChampionName, getChampionIdByName } = getRiotAssetsContext();
  const location = useLocation();
  const pathname = location?.pathname;
  const queryParams = useQueryString();
  const { region } = queryParams || {};
  const regionFull = region && REGION_OPTIONS_ALL.find((reg) => reg.value === region);
  const { fullLabel } = regionFull || {};

  const split = location.pathname?.split("/lol/champion-leaderboards/");
  const name = split && split[1];
  const id = getChampionIdByName(name);
  const champName = getChampionName(id);

  const getTitleAndDesc = (pathname) => {
    let title = "LoL Champions Search";
    let subtitle = "Discover the best builds for every champion";
    if (pathname && champName) {
      if (pathname === "/lol/champions") {
        title = "LoL Champions Search";
        subtitle = "Discover the best builds for every champion";
      }
      if (pathname.includes("/lol/champion-leaderboards/") && fullLabel) {
        title = `Best ${champName} Players in ${fullLabel === "World" ? `the ${fullLabel}` : fullLabel}`;
        subtitle = `${fullLabel}'s best ${champName} players, one-tricks, pros, and more. U.GG's Champion Leaderboard for all regions in D2+.`;
      } else if (pathname.includes("/lol/champion-leaderboards/")) {
        title = `Best ${champName} Players in the World`;
        subtitle = `World's best ${champName} players, one-tricks, pros, and more. U.GG's Champion Leaderboard for all regions in D2+.`;
      }
    }
    return { title, subtitle };
  };

  const { title, subtitle } = getTitleAndDesc(pathname) || {};

  const navTabs = [
    {
      path: "/lol/champions",
      label: "Champions",
    },
    {
      path: "/lol/champion-leaderboards",
      label: "Leaderboards",
    },
  ];

  return (
    <div className="flex mx-auto w-full max-md:w-full">
      <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE" renderNullOnFail isClient>
        <div className="flex-1 mr-[-12px]">
          <RampLeftRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)]" />
        </div>
      </MediaQuery>
      <div className="champion-home-page">
        <div className="champion-home-page_content content-side-padding">
          <div className="title-header champion-home-page_header">
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
          </div>
          <PageNav tabs={navTabs} />
          <Switch>
            <Route path="/lol/champions" render={(props) => <ChampionsFacePage {...props} />} />
            <Route
              path="/lol/champion-leaderboards"
              render={(props) => <ChampionLeaderboards {...props} championName={champName} regionFullName={fullLabel} />}
            />
            <Route path={`/lol/champion-leaderboards/:champName`} render={(props) => <ChampionLeaderboards {...props} />} />
            <Redirect to="/lol/champions" />
          </Switch>
        </div>
      </div>
      <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE" renderNullOnFail isClient>
        <div className="flex-1 ml-[-12px]">
          <RampRightRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)]" />
        </div>
      </MediaQuery>
    </div>
  );
};

export default ChampionsPage;
