import React, { Component } from "react";
import classNames from "classnames";
import { Rune } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useGlobal } from "reactn";
import { useLocation } from "react-router";

const RuneTreeCondensed = (props) => {
  const { perkStyle, activePerks, version, ssr } = props;
  const { getRuneJSON, useRiotRunes } = getRiotAssetsContext();
  const location = useLocation();
  const [language] = useGlobal("language");
  const { data, loading, error } = useRiotRunes({ patch: version, ssr, language });

  const buildPerks = (version) => {
    const path = data?.find((path) => {
      return path.id === Number(perkStyle);
    }).slots;
    let perksToDisplay = [];

    path?.forEach((row, index) => {
      row.runes.forEach((perk) => {
        if (activePerks.includes(perk.id)) {
          perksToDisplay.push(
            <div key={perk.id} className={classNames("mx-[6px]")}>
              <Rune
                className={classNames("w-[25px]", { "!w-[36px]": index === 0 })}
                runeId={perk.id}
                patch={version}
                disableTooltip={location.pathname === "/"}
                ssr={ssr}
              />
            </div>,
          );
        }
      });
    });

    return perksToDisplay;
  };

  const runeJSON = getRuneJSON(props.perkStyle, { patch: props.version, language, optionalData: data });

  if (!runeJSON) {
    return <div />;
  }

  return (
    <div
      className={classNames("grid grid-cols-[140px_1fr]", {
        "grid-cols-[auto_1fr]": props.pathLabel === false,
      })}
    >
      <div className="flex items-center col-start-1 col-end-2">
        <Rune className="w-[33px] mr-[12px]" runeId={perkStyle} patch={version} disableTooltip={false} ssr={ssr} />
        {props.pathLabel === false ? null : <div>{runeJSON.name}</div>}
      </div>
      <div className="flex items-center col-start-2 col-end-3">{buildPerks(props.version)}</div>
    </div>
  );
};

export default RuneTreeCondensed;
