import React, { Fragment } from "react";
import { PlaceholderCounters } from "components/Champions/Placeholders";
import RunesTable from "components/Champions/RunesTable/RunesTable";
import { getRunes } from "api/data_parser/runes-table.js";
import { useValidatedChampionProfileParams, useChampionRunes } from "lib/hooks/championProfileHooks";

const RunesTableContainer = (props) => {
  const { championId, championData, role } = props;

  const { data: runes, loading: fetchingRunes, error: errorRunes } = useChampionRunes(championId, { ssr: true });

  let { validatedParams } = useValidatedChampionProfileParams(championId, "runes-table", { keepDefaultParams: true });
  validatedParams = { ...validatedParams, role };

  if (fetchingRunes) {
    return <PlaceholderCounters />;
  }

  const championRunes = getRunes(runes, validatedParams);

  return <RunesTable championRunes={championRunes} patch={validatedParams.patch} />;
};

export default RunesTableContainer;
