import { window } from "global";
import React, { Fragment, useState, useEffect } from "react";
import { Switch, Link, Route, Redirect, useLocation, useHistory, useRouteMatch } from "react-router-dom";
import loadable from "@loadable/component";
import { useGlobal } from "reactn";
import { pathToRegexp } from "path-to-regexp";

import MediaQuery from "components/MediaQuery";
import ChampionProfileHeader from "components/Champions/ChampionProfileHeader";
import ChampionPageNav from "components/Champions/ChampionPageNav";
import FilterManager from "components/FilterManager/FilterManager";
import ChampionDuos from "components/Champions/ChampionDuos";
import Items from "components/Champions/Items";
// import ItemsTableContainer from 'components/Champions/ItemsTable/ItemsTableContainer';
import RunesTableContainer from "components/Champions/RunesTable/RunesTableContainer";
import Runes from "components/Champions/Runes";
import SpellsAbilities from "components/Champions/SpellsAbilities";
import Matchups from "components/Champions/Matchups";
import CountersContainer from "components/Champions/Counters/CountersContainer";
import Error404 from "components/Error404";
import { RampLeftRail, RampRightRail } from "components/Ads/RampRails";

import {
  overviews,
  getSpecialGameModeSeoName,
  getSpecialGameModeSeoNameFromPage,
  getSpecialGameModeFilterProps,
} from "lib/specialGameMode.js";
import { buildQueryParams, alphanumeric, SEO_ROLES } from "lib/general-helper.js";
import { getSpecialGameModeOverviewPage, getSpecialUrlName, getSpecialSSRPage } from "lib/specialGameMode";
import { useChampionProfileSEO } from "lib/seo_helper.js";
import { useUserPremiumState, useLoginState } from "lib/hooks/userHooks";
import {
  useValidatedChampionProfileParams,
  usePrimaryRoles,
  useCurrentRole,
  useNexusBlitzPrimaryRole,
} from "lib/hooks/championProfileHooks";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useLanguage } from "../../lib/hooks/languageHooks";
import { useQueryString } from "hooks/general-hooks";
import { validParams } from "query_parameters/valid_parameters";

// Loadable Components
const Overview = loadable(() => import("components/Champions/Overview"));
const ItemsTableContainer = loadable(() => import("components/Champions/ItemsTable/ItemsTableContainer"));

function getPageState(pathname) {
  if (getSpecialGameModeOverviewPage(pathname)) {
    return getSpecialGameModeOverviewPage(pathname);
  }
  const keys = [];
  //Add multi builds as we go
  const multis = ["ap", "tank", "ad", "crit", "lethality", "onhit", "red", "blue"];
  const url = `/lol/:language?/champions/:multi?/:champion/:page?/(.*)?`;
  const regexp = pathToRegexp(url, keys).exec(pathname);

  let page = regexp[4] || "";
  if (!multis.includes(regexp[2])) {
    page = regexp[3] || "";
  }

  switch (page) {
    case "counter":
    case "counters":
      return "counters";
    case (page.match(/(.+)-build/) || {}).input:
      return "build";
    default:
      return page;
  }
}

function isSSRPage(page) {
  if (getSpecialSSRPage(page)) {
    return getSpecialSSRPage(page);
  }

  switch (page) {
    case "build":
    case "runes":
    case "counters":
    case "items":
    case "runes-table":
      return true;
    default:
      return false;
  }
}

const ChampionProfile = (props) => {
  const { getChampionIdByName, useChampionIndiv, getChampionSplashImg } = getRiotAssetsContext();

  const location = useLocation();
  const history = useHistory();
  const searchParams = useQueryString();

  // const match = useMemoRouterMatch();
  const match = useRouteMatch();
  const [loggingIn] = useGlobal("loggingIn");
  const isLoggedIn = useLoginState();
  const { isPremium } = useUserPremiumState();
  const [language, setLanguage] = useLanguage();

  const getChampionProfileSEO = useChampionProfileSEO();
  const page = getPageState(location.pathname);
  const isSSR = isSSRPage(page);
  // Current champion info
  const routerMatchChampion = match.params.champion;
  const normalizedChampionName = alphanumeric(routerMatchChampion.split("-")[0]);
  const championId = getChampionIdByName(normalizedChampionName);
  let role = useCurrentRole(championId, page, { ssr: true });

  const { data: primaryRoles, loading: fetchingPrimaryRoles, error: errorPrimaryRoles } = usePrimaryRoles({ ssr: true });
  const {
    data: championIndiv,
    loading: fetchingChampionIndiv,
    error: errorChampionIndiv,
  } = useChampionIndiv(championId, { ssr: true });
  const championSplashImg = getChampionSplashImg(championId, { webp: true });

  const validatedParams = useValidatedChampionProfileParams(championId, page, { ssr: true });
  const validatedParamsWithDefaults = useValidatedChampionProfileParams(championId, page, { keepDefaultParams: true, ssr: true });

  const isNexusBlitz = validatedParamsWithDefaults.validatedParams.queueType === "nexus_blitz" || page === "overview_nexus_blitz";
  const { data: nexusBlitzPrimaryRole, loading: fetchingNexusBlitzPrimaryRole } = useNexusBlitzPrimaryRole(championId, {
    ssr: true,
    skip: !isNexusBlitz,
  });

  if (match.params.role && SEO_ROLES.includes(match.params.role)) {
    role = match.params.role === "mid" ? "middle" : match.params.role;
  } else if (isNexusBlitz && !fetchingNexusBlitzPrimaryRole && searchParams.role) {
    role = searchParams.role;
  }

  // Scroll to top
  useEffect(() => {
    if (location.state && location.state.scrollTop) {
      window && window.scrollTo(0, 0);
    }
  }, [location.state && location.state.scrollTop]);

  useEffect(() => {
    if (match.params?.language) {
      setLanguage(match.params.language);
    }
  }, [match.params?.language]);

  // Clean up query params
  useEffect(() => {
    if (primaryRoles) {
      const oldParams = location.search.charAt(0) === "?" ? location.search.substring(1) : location.search;
      if (oldParams !== validatedParams.search) {
        history.replace({
          pathname: validatedParams.pathname,
          search: validatedParams.search,
          state: {
            validation: true,
          },
        });
      }
    }
  }, [validatedParams, primaryRoles]);

  useEffect(() => {
    if (page && !fetchingNexusBlitzPrimaryRole) {
      const pageParams = validParams[page];
      const validNexusBlitzRoles = pageParams && "role" in pageParams ? pageParams.role : [];
      const hasValidRole = validNexusBlitzRoles.some((option) => {
        return option.value === searchParams.role;
      });
      if (isNexusBlitz && !hasValidRole) {
        history.replace({
          pathname: location.pathname,
          search: buildQueryParams({ ...searchParams, role: nexusBlitzPrimaryRole || "lane" }),
        });
      }
    }
  }, [page, fetchingNexusBlitzPrimaryRole, isNexusBlitz, searchParams.role, role]);

  // On mount
  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  if (fetchingNexusBlitzPrimaryRole || fetchingPrimaryRoles || fetchingChampionIndiv) {
    return <div className="champion-profile-container" />;
  }

  if (!championIndiv) {
    if (fetchingChampionIndiv) {
      return <div className="champion-profile-container" />;
    } else {
      return <Route component={Error404} />;
    }
  }

  const { name, title, key, id } = championIndiv;
  if (normalizedChampionName === "monkeyking") {
    const championName = "wukong";
    return <Redirect to={`/lol/champions/${championName}/${page}`} />;
  }

  const rootPathname = `/lol/champions/${id.toLowerCase()}`;

  let filterProps = {};
  let seoName = page;
  if (page === "matchups") {
    filterProps = {
      enable: ["showAll"],
    };
    seoName = "champion_matchups";
  } else if (page === "counters") {
    seoName = "champion_counters";
  } else if (page === "duos") {
    filterProps = {
      enable: ["showAll"],
    };
    seoName = "champion_duos";
  } else if (page === "build") {
    filterProps = {
      enable: ["oppChampion"],
    };

    if (getSpecialGameModeSeoName(location.pathname)) {
      seoName = getSpecialGameModeSeoName(location.pathname);
    } else {
      seoName = "champion_overview";
    }
  } else if (page === "items") {
    seoName = "champion_items";
  } else if (page === "item-paths") {
    seoName = "champion_item_paths";
  } else if (page === "runes-table") {
    seoName = "champion_runes_table";
  } else if (getSpecialGameModeFilterProps(page)) {
    filterProps = getSpecialGameModeFilterProps(page);
    seoName = getSpecialGameModeSeoNameFromPage(page);
  }

  filterProps = Object.assign(
    {
      championId: key,
      normalizedChampionName,
      page,
      role,
    },
    filterProps,
  );

  const componentProps = {
    match,
    location,
    history,
    name,
    title,
    championKey: id,
    championId: key,
    championData: championIndiv,
    normalizedChampionName,
    role,
    page,
  };

  const backgroundStyle = {
    backgroundRepeat: "no-repeat",
    backgroundSize: "1014px 570px",
    backgroundPosition: "50% -60px",
    backgroundImage: `
      radial-gradient(400px 200px at 60% 34%, rgba(7, 7, 32, 0) 0%, #070720 100%),
      linear-gradient(90deg, #070720 0%, rgba(7, 7, 32, 0.6) 100%),
      url(${championSplashImg})
    `,
  };

  let specialRoutes = [];
  for (const [index, value] of getSpecialUrlName().entries()) {
    specialRoutes.push(
      <Route
        key={index}
        path={`/lol/champions/${value}/:multi?/:champhyphened`}
        render={(props) => <Overview {...componentProps} />}
      />,
    );
  }

  return (
    <div className="flex mx-auto w-full max-md:w-full">
      <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE" renderNullOnFail isClient>
        <div className="flex-1 mr-[-12px]">
          <RampLeftRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)]" />
        </div>
      </MediaQuery>
      <div className="champion-profile-main-page">
        <div className="champion-profile-content-container content-side-padding">
          <div className={`champion-profile-container page_${page}`} style={backgroundStyle}>
            <div>
              {getChampionProfileSEO(seoName, {
                champion: championIndiv,
                patch: validatedParamsWithDefaults.validatedParams.patch,
                region: language,
                role: role,
              })}
              <ChampionProfileHeader {...componentProps} />
              <ChampionPageNav championKey={id} />
              <FilterManager {...filterProps} />
              <div className="champion-profile-page">
                <Switch>
                  <Route path="/lol/champions/aram/:multi?/:champhyphened" render={(props) => <Overview {...componentProps} />} />
                  <Route
                    path="/lol/:language?/champions/:multi?/:champion/build/:role?"
                    render={(props) => <Overview {...componentProps} />}
                  />
                  {specialRoutes}
                  <Route
                    exact
                    path="/lol/champions/:champion/build/:role?"
                    render={(props) => <Overview {...componentProps} />}
                  />
                  <Route path="/lol/champions/:champion/counter" render={(props) => <CountersContainer {...componentProps} />} />
                  <Redirect
                    from="/lol/champions/:champion/counters"
                    to={{ pathname: "/lol/champions/:champion/counter", search: validatedParams.search }}
                  />
                  <Route path="/lol/champions/:champion/matchups" render={(props) => <Matchups {...componentProps} />} />
                  <Route path="/lol/champions/:champion/items" render={(props) => <ItemsTableContainer {...componentProps} />} />
                  <Route path="/lol/champions/:champion/item-paths" render={(props) => <Items {...componentProps} />} />
                  <Route
                    path="/lol/champions/:champion/runes-table"
                    render={(props) => <RunesTableContainer {...componentProps} />}
                  />
                  <Route path="/lol/champions/:champion/rune-sets" render={(props) => <Runes {...componentProps} />} />
                  <Route
                    path="/lol/champions/:champion/spells-abilities"
                    render={(props) => <SpellsAbilities {...componentProps} />}
                  />
                  <Route path="/lol/champions/:champion/duos" render={(props) => <ChampionDuos {...componentProps} />} />

                  <Redirect from="/lol/champions/:champion" to="/lol/champions/:champion/build" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE" renderNullOnFail isClient>
        <div className="flex-1 ml-[-12px]">
          <RampRightRail className="sticky top-[calc(var(--masthead-height)+var(--game-nav-height)+24px)]" />
        </div>
      </MediaQuery>
    </div>
  );
};

export default ChampionProfile;
