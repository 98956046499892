import React, { useRef } from "react";
import { ReactComponent as AlarmWarning } from "../../svg/alarm-warning.svg";
import { ReactComponent as FiltersLogo } from "../../svg/filters-logo.svg";

const NoData = () => {
  return (
    <div className="no-data">
      <div>
        <AlarmWarning style={{ marginBottom: "28px" }} />
      </div>
      <div>
        No games for current <FiltersLogo style={{ width: "18", height: "10" }} />
        <span> Filters.</span> Refresh the page or broaden your search.
      </div>
    </div>
  );
};

export default NoData;
