import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getRankings } from "api/data_parser/ranking_stats.js";
import { useValidatedChampionProfileParams, usePrimaryRoles, useChampionRankingStats } from "lib/hooks/championProfileHooks";

import MediaQuery from "components/MediaQuery";
import FavoriteChampionButton from "components/Champions/FavoriteChampionButton";
import BuildSEO from "components/Champions/ChampionSEO/BuildSEO";
import CountersSEO from "components/Champions/ChampionSEO/CountersSEO";
import ChampionSkillWithLabel from "components/common/Skills/ChampionSkillWithLabel";
import { ChampionSkill } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { validParams } from "query_parameters/valid_parameters";
import { getLuTier, getLuTierColorHex } from "lib/tierHelper";
import { getForcedParams, getSpecialPageH1Suffix, isSpecialGameModeByPage, SPECIAL_GAME_MODES } from "lib/specialGameMode";
import { SEO_ROLES } from "lib/general-helper";

function getPageH1Suffix({ championId, role, page, rank, isSEORoleBuild, multi }) {
  const { t } = useTranslation();
  const roleTextMap = {
    adc: "ADC",
    support: "Support",
    middle: "Mid",
    top: "Top",
    jungle: "Jungle",
  };
  const roleText = t(roleTextMap[role]);

  let specialPageH1Suffix = getSpecialPageH1Suffix(page);
  if (specialPageH1Suffix) {
    if (page === SPECIAL_GAME_MODES.ONE_FOR_ALL.buildPageName) {
      return `${t(specialPageH1Suffix)} ${roleText}`;
    }
    return `${t(specialPageH1Suffix)}`;
  }

  if (isSEORoleBuild && page === "build") {
    return multi ? `Build for ${roleText}, ${t(rank)}` : `${roleText} Build, ${t(rank)}`;
  }

  switch (page) {
    case "build":
      return `${t(`Build for`)} ${roleText}, ${t(rank)}`;
    case "items":
      return `${t(`Items for`)} ${roleText}`;
    case "item-paths":
      return `${t(`Build Paths for`)} ${roleText}`;
    case "spells-abilities":
      return `${t(`Spells and Abilities for`)} ${roleText}`;
    case "runes":
      return `${t(`Rune for`)} ${roleText}, ${t(rank)}`;
    case "runes-table":
      return `${roleText} ${t(`Runes Table`)} `;
    case "rune-sets":
      return `${roleText} ${t(`Runes Sets`)} `;
    case "counters":
      return `${t(`Counter for`)} ${roleText}`;
    case "matchups":
      return `${t(`Matchups for`)} ${roleText}`;
    case "duos":
      return `${t(`Duos for`)} ${roleText}`;
  }
}

function capitalizeMulti(multi) {
  switch (multi) {
    case "ap":
    case "ad":
      return multi.toUpperCase();
    case "tank":
    case "crit":
    case "lethality":
      return multi.substring(0, 1).toUpperCase() + multi.substring(1);
    case "onhit":
      return "On Hit";
    case "red":
      return "Red";
    case "blue":
      return "Blue";
  }
}

const SEOFluff = (props) => {
  const { className, page, patch, championId, role, rank, multi } = props;
  const { data: primaryRoles } = usePrimaryRoles({ ssr: true });
  const roles = primaryRoles && primaryRoles[championId];
  const displayPatch = patch ? patch.replace("_", ".") : "";

  if (page === "counters") {
    return <CountersSEO className={className} championId={championId} patch={patch} displayPatch={displayPatch} roles={roles} />;
  } else if (page === "build" || isSpecialGameModeByPage(page)) {
    return (
      <BuildSEO
        className={className}
        page={page}
        displayPatch={displayPatch}
        championId={championId}
        roleText={role}
        rank={rank}
        isSEORoleBuild={props.isSEORoleBuild}
        multi={multi}
      />
    );
  }

  return null;
};

const ChampionSkills = (props) => {
  // Passive, Q, W, E, R
  const skillSlots = [
    { skillKey: "Q", skillSlot: 0 },
    { skillKey: "W", skillSlot: 1 },
    { skillKey: "E", skillSlot: 2 },
    { skillKey: "R", skillSlot: 3 },
  ];

  return (
    <div className="champion-skills">
      <ChampionSkillWithLabel
        label={"P"}
        labelPosition={"bottomCenter"}
        isPassive
        championId={props.championId}
        forceOrientation="bottom"
        ssr
      />
      {skillSlots.map((skill) => (
        <ChampionSkillWithLabel
          key={skill.skillKey}
          label={skill.skillKey}
          labelPosition={"bottomCenter"}
          championId={props.championId}
          skillSlot={skill.skillSlot}
          forceOrientation="bottom"
          ssr
        />
      ))}
    </div>
  );
};

const ChampionProfileHeader = (props) => {
  const { getChampionImg } = getRiotAssetsContext();
  const location = useLocation();
  const { t } = useTranslation();

  let forcedParams = {};
  if (getForcedParams(location)) {
    forcedParams = getForcedParams(location);
  }

  const { page, name, championKey, championId, role, championData, match } = props;

  const multi = match.params.multi;
  const { validatedParams } = useValidatedChampionProfileParams(championId, "build", { keepDefaultParams: true, ssr: true });
  const {
    data: rankingStats,
    loading: fetchingRankingStats,
    error: errorRankingStats,
  } = useChampionRankingStats(championId, { ssr: true, forcedParams });

  if (!championData) {
    return null;
  }

  const validatedParamsRankingStats = {
    ...validatedParams,
    ...forcedParams,
    role,
  };

  const filteredRankingStats = getRankings(rankingStats, validatedParamsRankingStats);
  const { stdevs, rank } = filteredRankingStats || {};
  const tier = isNaN(stdevs) || !rank ? null : getLuTier(stdevs);
  const tierColor = !rank ? "#414165" : tier === "B" ? "#CDDCFE" : getLuTierColorHex(stdevs);

  const validPageParams = validParams[page] || {};
  const rankOptions = validPageParams.rank || [];
  const currentRank = rankOptions.find((rank) => rank.value === validatedParams.rank);
  const h1Suffix = getPageH1Suffix({
    championId,
    role,
    page,
    rank: currentRank && currentRank.label,
    isSEORoleBuild: SEO_ROLES.includes(props.match.params.role),
    multi: multi,
  });

  const { patch } = validatedParamsRankingStats;
  const seoFluffProps = {
    page,
    patch,
    championId,
    role,
    rank: currentRank && currentRank.label,
    isSEORoleBuild: SEO_ROLES.includes(props.match.params.role),
    multi,
  };

  return (
    <div className="champion-header">
      <div className="champion-header-wrapper">
        <div className="champion-image-container" style={{ borderColor: tierColor }}>
          {tier && (
            <div className="tier-header" style={{ borderColor: tierColor }}>
              {tier}
            </div>
          )}
          <div className="champion-image-border">
            {tier && <div className="border-notch" />}
            <img className="champion-image" src={getChampionImg(championId, { webp: true })} alt={name} />
          </div>
          <FavoriteChampionButton championId={championId} />
        </div>
        <div className="champion-header-info">
          <h1 className="champion-label">
            <span className="champion-name">
              {multi ? capitalizeMulti(multi) : ""} {name}
            </span>{" "}
            <span className="champion-title">{h1Suffix}</span>
            <MediaQuery min="TABLET" max="DESKTOP_LARGE">
              {patch && (
                <div className="header-patch">
                  <span>Patch&nbsp;</span>
                  {patch.replace("_", ".")}
                </div>
              )}
            </MediaQuery>
          </h1>
          <div className="champion-header__row-2">
            <ChampionSkills championId={championId} />
            <MediaQuery min="TABLET" max="DESKTOP_LARGE">
              <SEOFluff {...seoFluffProps} />
            </MediaQuery>
          </div>
        </div>
      </div>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
        <SEOFluff {...seoFluffProps} className="seo-fluff_mobile" />
      </MediaQuery>
    </div>
  );
};

export default ChampionProfileHeader;
