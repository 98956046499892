import React from "react";
import classnames from "classnames";
import { Rune } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useGlobal } from "reactn";

const RuneTree = (props) => {
  const { getRuneJSON, useRiotRunes } = getRiotAssetsContext();
  const { patch, perkStyle, primaryPerk, activePerks, ssr, skip, isMobile } = props;

  const [language] = useGlobal("language");
  const { data, loading, error } = useRiotRunes({ patch, ssr, skip, language });
  const runeJSON = getRuneJSON(perkStyle, { patch, language, optionalData: data });

  const buildPerks = (row) => {
    let zeroActive = true;
    const path = runeJSON.slots;
    const list = path[row].runes.map((perk) => {
      const isActive = activePerks.find((id) => perk.id === Number(id));
      if (isActive) zeroActive = false;

      const perkClassNames = classnames("perk", {
        keystone: primaryPerk && row === 0,
        "perk-active": isActive,
        "perk-inactive": !isActive,
      });

      return (
        <div key={perk.id} className={perkClassNames}>
          <Rune runeId={perk.id} patch={patch} disableTooltip={props.disableTooltip} ssr={ssr} language={language} webp />
        </div>
      );
    });

    const perkRowClassNames = classnames("perk-row", {
      "keystone-row": primaryPerk && row === 0,
      zeroActive: zeroActive,
    });

    if (!(primaryPerk || row > 0)) {
      return null;
    }

    return (
      <div key={row} className={perkRowClassNames}>
        {row >= 0 && <div className={classnames("row-marker", { "row-active": !zeroActive })} />}
        <div className="perks">{list}</div>
      </div>
    );
  };

  if (!runeJSON) {
    return null;
  }

  return (
    <div className={classnames({ "rune-tree_mobile": isMobile })}>
      <div className={classnames("rune-tree_v2", { "primary-tree": primaryPerk })}>
        <div className="rune-tree_header">
          <div className="rune-image-container">
            <Rune runeId={perkStyle} patch={patch} disableTooltip={true} ssr={ssr} webp />
          </div>
          <div className="perk-style-title">{runeJSON.name}</div>
        </div>
        {[0, 1, 2, 3].map((row) => buildPerks(row))}
      </div>
    </div>
  );
};

export default RuneTree;
