import React from "react";
import MatchupsTable from "./MatchupsTable.js";
import { getMatchups } from "api/data_parser/matchups";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useValidatedChampionProfileParams, useChampionMatchups } from "lib/hooks/championProfileHooks";

const Matchups = (props) => {
  const { getChampionName } = getRiotAssetsContext();
  const { championId, role } = props;

  const { data: matchups, loading: fetchingMatchups, error: errorMatchups } = useChampionMatchups(championId, { ssr: true });

  let { validatedParams } = useValidatedChampionProfileParams(championId, "matchups", { keepDefaultParams: true });
  validatedParams = { ...validatedParams, role };

  if (fetchingMatchups) {
    return <div className="champion-profile-placeholder basic-placeholder shimmer-placeholder" />;
  }

  let filteredMatchups = getMatchups(matchups, validatedParams);
  filteredMatchups = (filteredMatchups && filteredMatchups.counters) || [];

  if (validatedParams.allChampions !== "true") {
    filteredMatchups = filteredMatchups.filter((champion) => champion.pick_rate >= 0.5);
  }
  return (
    <div>
      <MatchupsTable data={filteredMatchups} championId={championId} role={props.role} />
    </div>
  );
};

export default Matchups;
