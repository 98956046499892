import React, { Component, useMemo, useRef, useEffect } from "react";
import { useGlobal } from "reactn";
import { getRankBorderImg, getRiotAssetsContext } from "@outplayed/riot-assets";
import { numToRoleMapping } from "lib/general-helper.js";
import {
  // getTier,
  getTierColor,
} from "lib/tierHelper";
import { useLocation } from "react-router-dom";
import { numberWithCommas } from "lib/format_helper.js";
import NoData from "./NoData";
import { useValidateQueryParams } from "query_parameters/paramsHelper";
import { ChampionLink } from "@ugg/shared/components/TierList/common/ChampionLink";
import { getRoleImg } from "lib/role_helper.js";
import { TooltipContainer } from "@outplayed/tooltips";
import classnames from "classnames";
import { useQueryString } from "hooks/general-hooks";
import { useTable, useSortBy, usePagination } from "react-table";
import { isSpecialGameModeByQueueType, getSpecialGameModeTierListSettings } from "lib/specialGameMode";
import { RoleC } from "@ugg/shared/utils/role-helpers";

function getDuoHeader(dataFor, extra) {
  return (
    <TooltipContainer tooltipInfo={`Duo's ${extra ? extra : ""}${dataFor} Difference at 15 minutes`}>
      <div>{dataFor} @15</div>
    </TooltipContainer>
  );
}

const ChampionDuosTable = (props) => {
  const { getChampionName } = getRiotAssetsContext();
  const { data } = props;
  const prevSort = useRef(null);
  const [windowBreakpoint] = useGlobal("responsive");
  const validateQueryParams = useValidateQueryParams();
  const location = useLocation();

  let queryParams = useQueryString();
  queryParams = validateQueryParams({
    queryParams,
    page: "duos",
    keepDefaultParams: true,
  });

  const DUO_PAGE_SIZE = 40;

  const isMobile =
    windowBreakpoint === "MOBILE_SMALL" || windowBreakpoint === "MOBILE_MEDIUM" || windowBreakpoint === "MOBILE_LARGE";
  const isMobileSmall = windowBreakpoint === "MOBILE_SMALL" || windowBreakpoint === "MOBILE_MEDIUM";
  if (!data || data.length == 0) {
    return <NoData />;
  }

  const DESKTOP_WIDTH = 100;
  const columns = useMemo(
    () => [
      {
        id: "rank",
        Header: !isMobileSmall ? "Rank" : "",
        headerClassName: "rank",
        className: "rank",
        Cell: (props) => <span>{props.index + 1}</span>,
        sortable: false,
        width: !isMobileSmall ? 60 : 30,
        minWidth: !isMobileSmall ? 40 : 30,
        maxWidth: !isMobileSmall ? 55 : 30,
      },
      {
        Header: !isMobileSmall ? "Role" : "",
        headerClassName: "role",
        accessor: "role",
        Cell: (props) => (
          <div>
            <img
              className="w-[20px] h-[20px]"
              src={getRoleImg(numToRoleMapping(props.value))}
              alt={numToRoleMapping(props.value)}
            />
          </div>
        ),
        sortable: false,
        minWidth: !isMobileSmall ? 40 : 30,
        maxWidth: !isMobileSmall ? 55 : 30,
      },
      {
        Header: !isMobileSmall ? "Duo Champion" : "",
        headerClassName: "duo_champion",
        accessor: "champion_link",
        className: "champion",
        sortType: (a, b) => {
          const champNameA = getChampionName(a.values.champion_link.champion_id);
          const champNameB = getChampionName(b.values.champion_link.champion_id);
          return champNameB.localeCompare(champNameA);
        },
        Cell: ({ cell: { value } }) => (
          <ChampionLink
            championId={value.champion_id}
            role={RoleC.convertToString(value.role)}
            location={location}
            dim={isMobileSmall ? 24 : 30}
          />
        ),
        sortable: true,
        minWidth: !isMobileSmall ? 155 : 40,
        maxWidth: !isMobileSmall ? undefined : 40,
      },
      // {
      //   Header: "Tier",
      //   headerClassName: "tier",
      //   accessor: "tier",
      //   id: "tier",
      //   className: "tier",
      //   minWidth: defaultColWidth,
      //   sortMethod: (a, b) => {
      //     if(a.pick_rate >= .5 && b.pick_rate >= .5) {
      //       if(a.win_rate > b.win_rate) return 1;
      //       else if(a.win_rate < b.win_rate) return -1;
      //       return 0;
      //     }
      //     else if(a.pick_rate >= .5 && b.pick_rate < .5) {
      //       return 1;
      //     }
      //     else if(a.pick_rate < .5 && b.pick_rate >= .5) {
      //       return -1;
      //     }
      //     else if(a.pick_rate < .5 && b.pick_rate < .5) {
      //       if(a.win_rate > b.win_rate) return 1;
      //       else if(a.win_rate < b.win_rate) return -1;
      //       return 0;
      //     }
      //     return 0;
      //   },
      //   Cell: props => {
      //     if(props.value.pick_rate < 0.5) {
      //       return <span><b>?</b></span>
      //     }
      //     return (
      //       <span className={getTierColor(props.value.win_rate)}>
      //         <b>{getTier(props.value.win_rate)}</b>
      //       </span>
      //     )
      //   }
      // },
      {
        Header: !isMobile ? "Duo Win Rate" : "Win Rate",
        headerClassName: "win_rate",
        accessor: "win_rate",
        className: "win_rate",
        id: "winrate",
        sortType: (a, b) => {
          return a.values.winrate - b.values.winrate;
        },
        Cell: ({ cell: { value } }) => (
          <span className={getTierColor(value.toFixed(2))}>
            <b>{value.toFixed(2) + "%"}</b>
          </span>
        ),
        sortable: true,
        sortDescFirst: true,
        minWidth: !isMobileSmall ? 40 : 65,
        maxWidth: !isMobileSmall ? 100 : undefined,
      },
      {
        Header: getDuoHeader("Gold"),
        accessor: "gold_adv_15",
        headerClassName: "gold",
        className: "gold",
        sortable: true,
        minWidth: !isMobileSmall ? 80 : 63,
        maxWidth: !isMobileSmall ? DESKTOP_WIDTH : 70,
        sortType: (a, b) => {
          return a.values.gold_adv_15 - b.values.gold_adv_15;
        },
      },
      {
        Header: getDuoHeader("XP"),
        accessor: "xp_adv_15",
        headerClassName: "xp",
        className: "xp",
        sortable: true,
        minWidth: !isMobileSmall ? 80 : 63,
        maxWidth: !isMobileSmall ? DESKTOP_WIDTH : 70,
        sortType: (a, b) => {
          return a.values.xp_adv_15 - b.values.xp_adv_15;
        },
      },
      {
        Header: getDuoHeader("Kills"),
        accessor: "kill_adv_15",
        headerClassName: "kill",
        className: "kill",
        sortable: true,
        minWidth: !isMobileSmall ? 80 : 63,
        maxWidth: !isMobileSmall ? DESKTOP_WIDTH : 70,
        sortType: (a, b) => {
          return a.values.kill_adv_15 - b.values.kill_adv_15;
        },
      },
      {
        Header: getDuoHeader("CS"),
        accessor: "cs_adv_15",
        headerClassName: "cs",
        className: "cs",
        sortable: true,
        minWidth: !isMobileSmall ? 80 : 63,
        maxWidth: !isMobileSmall ? DESKTOP_WIDTH : 70,
        sortType: (a, b) => {
          return a.values.cs_adv_15 - b.values.cs_adv_15;
        },
      },
      {
        Header: !isMobile ? getDuoHeader("Team Gold") : getDuoHeader("T Gold", "Team "),
        accessor: "team_gold_difference_15",
        headerClassName: "team_gold",
        className: "team_gold",
        sortable: true,
        width: !isMobileSmall ? 105 : 60,
        minWidth: !isMobileSmall ? 80 : 63,
        maxWidth: !isMobileSmall ? 110 : 70,
        sortType: (a, b) => {
          return a.values.team_gold_difference_15 - b.values.team_gold_difference_15;
        },
        Cell: (props) => <span>{numberWithCommas(props.value.toFixed(0))}</span>,
      },
      {
        Header: "Matches",
        accessor: "matches",
        headerClassName: "matches",
        className: "matches",
        minWidth: !isMobileSmall ? 80 : 60,
        maxWidth: !isMobileSmall ? 117 : undefined,
        sortDescFirst: true,
        Cell: ({ cell: { value } }) => <span>{numberWithCommas(value.toFixed(0))}</span>,
      },
    ],
    [windowBreakpoint],
  );

  const getMaxWidth = (queryParams, column) => {
    let maxWidth = column.totalMaxWidth;
    if (isSpecialGameModeByQueueType(queryParams.queueType)) {
      maxWidth = undefined;
    }
    return maxWidth;
  };

  const columnStyle = (queryParams, column) => ({
    minWidth: column.totalMinWidth,
    maxWidth: getMaxWidth(queryParams, column),
    flex: `1 1 100%`,
  });

  const {
    state,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    preSortedRows,
    prepareRow,
    setHiddenColumns,
    setPageSize,
  } = useTable(
    {
      columns,
      data: data,
      initialState: {
        pageSize: DUO_PAGE_SIZE,
        pageIndex: 0,
        sortBy: prevSort.current || [{ id: "winrate", desc: true }],
      },
      disableSortRemove: true,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    setPageSize(DUO_PAGE_SIZE);
  }, [windowBreakpoint]);

  useEffect(() => {
    if (prevSort.current) {
      if (prevSort.current[0].id !== state.sortBy[0].id || prevSort.current[0].desc !== state.sortBy[0].desc) {
        prevSort.current = state.sortBy;
        setPageSize(DUO_PAGE_SIZE);
      }
    } else {
      prevSort.current = state.sortBy;
    }
  }, [state.sortBy]);

  return (
    <div
      {...getTableProps({
        className: "content-section ReactTable ugg-table-2 duos-list-table scrollable-table",
      })}
    >
      <div className={"rt-thead -header"}>
        {headerGroups.map((headerGroup, index) => (
          <div key={index} {...headerGroup.getHeaderGroupProps({ className: "rt-tr" })}>
            {headerGroup.headers.map((column, index) => {
              const thClassNames = classnames([
                "rt-th",
                column.headerClassName,
                column.isSorted && !column.isSortedDesc ? "-sort-asc" : "",
                column.isSorted && column.isSortedDesc ? "-sort-desc" : "",
              ]);

              return (
                <div
                  key={index}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      className: thClassNames,
                      style: columnStyle(queryParams, column),
                    }),
                  )}
                >
                  {column.render("Header")}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps({ className: "rt-tbody" })}>
        {page.map((row, i) => {
          prepareRow(row);
          const { values } = row;

          return (
            <div key={i} className="rt-tr-group">
              <div {...row.getRowProps({ className: "rt-tr" })}>
                {row.cells.map((cell, index) => {
                  const tdClassNames = classnames("rt-td", cell.column.className, {
                    sorted: cell.column.isSorted,
                    "is-in-odd-row": i % 2 === 0,
                  });

                  return (
                    <div
                      key={index}
                      {...cell.getCellProps({
                        className: tdClassNames,
                        style: columnStyle(queryParams, cell.column),
                      })}
                    >
                      {cell.render("Cell", { index: i })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChampionDuosTable;
