import React, { Fragment } from "react";
import ChampionDuosTable from "./ChampionDuosTable.js";
import { PlaceholderCounters } from "./Placeholders";
import { getDuos } from "api/data_parser/champion_duos";
import { useValidatedChampionProfileParams, useChampionDuos } from "lib/hooks/championProfileHooks";

const ChampionDuos = (props) => {
  const { championId, championData, role } = props;

  const { data: duos, loading: fetchingDuos, error: errorDuos } = useChampionDuos(championId, { ssr: true });

  let { validatedParams } = useValidatedChampionProfileParams(championId, "duos", { keepDefaultParams: true });
  validatedParams = { ...validatedParams, role };

  if (fetchingDuos) {
    return <PlaceholderCounters />;
  }

  let championDuos = getDuos(duos, validatedParams) || {};
  championDuos = (championDuos && championDuos.duos) || [];

  if (validatedParams.allChampions !== "true") {
    championDuos = championDuos.filter((champion) => champion.pick_rate >= 0.5);
  }

  return (
    <div style={{ marginTop: "24px" }}>
      <ChampionDuosTable data={championDuos} championId={championId} />
    </div>
  );
};

export default ChampionDuos;
