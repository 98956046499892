import React, { useState } from "react";
import ReactSVG from "react-svg";
import _round from "lodash.round";
import { useTranslation } from "react-i18next";

import { getOverview } from "api/data_parser/champion_overview.js";
import { numberWithCommas } from "lib/general-helper.js";
import * as championsHelper from "components/Champions/championsHelper.js";
import { getNotFoundImg } from "@outplayed/riot-assets";
import { ReactComponent as Checkmark } from "../../svg/checkmark.svg";
import { useValidatedChampionProfileParams, useChampionRecBuild } from "lib/hooks/championProfileHooks";

const Items = (props) => {
  const { t } = useTranslation();
  const [includeBoots, setBoots] = useState(false);
  const [sort, setSort] = useState({
    coreItemsSort: ["pick", true], // or "win"
    startingItemsSort: ["pick", true], // or "win"
    bootsSort: ["pick", true], // or "win"
  });

  const displayRows = (array, content, totalMatches, coreItems) => {
    return array.map((el, index) => {
      return (
        <div key={index} className={`grid-block-content-row ${coreItems ? "core-items" : ""}`}>
          {content[index]}
          <div className="content-row-right-half">
            <div className="win-rate">{`${_round(el.win_rate)}% `}</div>
            <div className="pick-rate">
              <div>{`${_round((el.matches / totalMatches) * 100, 2)}% `}</div>
              <div className="pick-matches">{numberWithCommas(el.matches)}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  const setSortBy = (sortContent, sortBy) => {
    setSort({
      ...sort,
      [sortContent]: [sortBy, sort[sortContent][0] === sortBy ? !sort[sortContent][1] : true],
    });
  };

  const sortData = (array, contentType) => {
    const list = sort[contentType];
    const sortBy = list[0];
    const dir = list[1];

    array.sort((a, b) => {
      if (sortBy === "pick") {
        return dir ? b.matches - a.matches : a.matches - b.matches;
      } else if (sortBy === "win") {
        return dir ? b.wins / b.matches - a.wins / a.matches : a.wins / a.matches - b.wins / b.matches;
      }
    });
  };

  const sortArrow = (sortContent, sortBy) => {
    if (sort[sortContent][0] === sortBy) {
      return (
        <ReactSVG
          wrapperClassName="sort-arrows"
          className="sorted"
          path={
            sort[sortContent][1]
              ? "https://static.bigbrain.gg/assets/ugg/icons/arrow-down.svg"
              : "https://static.bigbrain.gg/assets/ugg/icons/arrow-up.svg"
          }
        />
      );
    } else {
      return <ReactSVG wrapperClassName="sort-arrows" path={"https://static.bigbrain.gg/assets/ugg/icons/arrows-sort.svg"} />;
    }
  };

  const { championId, championData, role } = props;

  const {
    data: builds,
    loading: fetchingBuilds,
    error: errorBuilds,
  } = useChampionRecBuild(championId, "item-paths", { ssr: true });

  let { validatedParams } = useValidatedChampionProfileParams(championId, "item-paths", { keepDefaultParams: true });
  validatedParams = { ...validatedParams, role };
  const patch = validatedParams.patch;
  const championBuilds = getOverview(builds, validatedParams);

  if (fetchingBuilds) {
    return <div className="champion-profile-placeholder basic-placeholder shimmer-placeholder" />;
  }

  if (!championBuilds || errorBuilds) {
    return (
      <div className="content-section champion-profile-error">
        <img src={getNotFoundImg()} alt="No data found" />
        No data found. Please try again or change filters.
      </div>
    );
  }

  const { starting_items, core_items, core_items_with_boots, boots } = championBuilds || {};

  let startingItemsMatches = 0,
    bootsMatches = 0,
    coreItemsMatches = 0;

  if (!core_items && !starting_items && !boots) {
    return (
      <div className="content-section champion-profile-error">
        <img src={getNotFoundImg()} alt="No data found" />
        No data found. Please try again or change filters.
      </div>
    );
  }

  sortData(starting_items, "startingItemsSort");
  sortData(core_items, "coreItemsSort");
  sortData(core_items_with_boots, "coreItemsSort");
  sortData(boots, "bootsSort");

  const startingItemsList = starting_items.map((set) => {
    startingItemsMatches += set.matches;
    return championsHelper.displayItems(set.items, false, "NO ITEMS", patch, 36, true);
  });

  const bootsList = boots.map((set) => {
    bootsMatches += set.matches;
    return championsHelper.displayItems(set.items, false, "NO LVL 2 BOOTS", patch, 36, true);
  });

  const coreItemsList = (includeBoots ? core_items_with_boots : core_items).map((set) => {
    coreItemsMatches += set.matches;
    return championsHelper.displayItems(set.items, true, null, patch, 36, true);
  });

  return (
    <div className="champion-items">
      <div className="champion-items-left">
        <div className="starting-items grid-block">
          <div className="grid-block-header">
            <div className="header-left-half">
              <div className="bluebar" />
              <div className="title">{t(`Starting Items`)}</div>
            </div>
            <div className="header-right-half">
              <div className="title sortable" onClick={setSortBy.bind(null, "startingItemsSort", "win")}>
                <span
                  style={{
                    color: sort.startingItemsSort[0] === "win" ? "white" : "",
                    fontWeight: sort.startingItemsSort[0] === "win" ? "600" : "",
                  }}
                >
                  {t(`Win Rate`)}
                </span>
                {sort.startingItemsSort[0] === "win" && <div className="blue-bar-horz" />}
              </div>
              <div
                className="title sortable"
                onClick={setSortBy.bind(null, "startingItemsSort", "pick")}
                style={{ marginRight: "3px" }}
              >
                <span
                  style={{
                    color: sort.startingItemsSort[0] === "pick" ? "white" : "",
                    fontWeight: sort.startingItemsSort[0] === "pick" ? "600" : "",
                  }}
                >
                  {t(`Pick Rate`)}
                </span>
                {sort.startingItemsSort[0] === "pick" && <div className="blue-bar-horz" />}
              </div>
            </div>
          </div>
          <div className="grid-block-content">{displayRows(starting_items, startingItemsList, startingItemsMatches)}</div>
        </div>
        <div className="boots grid-block">
          <div className="grid-block-header">
            <div className="header-left-half">
              <div className="bluebar" />
              <div className="title">{t(`Boots`)}</div>
            </div>
            <div className="header-right-half">
              <div className="title sortable" onClick={setSortBy.bind(null, "bootsSort", "win")}>
                <span
                  style={{
                    color: sort.bootsSort[0] === "win" ? "white" : "",
                    fontWeight: sort.bootsSort[0] === "win" ? "600" : "",
                  }}
                >
                  {t(`Win Rate`)}
                </span>
                {sort.bootsSort[0] === "win" && <div className="blue-bar-horz" />}
              </div>
              <div className="title sortable" onClick={setSortBy.bind(null, "bootsSort", "pick")}>
                <span
                  style={{
                    color: sort.bootsSort[0] === "pick" ? "white" : "",
                    fontWeight: sort.bootsSort[0] === "pick" ? "600" : "",
                  }}
                >
                  {t(`Pick Rate`)}
                </span>
                {sort.bootsSort[0] === "pick" && <div className="blue-bar-horz" />}
              </div>
            </div>
          </div>
          <div className="grid-block-content">{displayRows(boots, bootsList, bootsMatches)}</div>
        </div>
      </div>
      <div className="champion-items-right">
        <div className="core-items grid-block">
          <div className="grid-block-header">
            <div className="header-left-half">
              <div className="bluebar" />
              <div className="title">
                {t(`Core Items`)}
                <div
                  style={{
                    display: "inline-block",
                    marginLeft: "15px",
                    userSelect: "none",
                  }}
                >
                  <label
                    htmlFor="boots"
                    style={{
                      marginLeft: "5px",
                      marginBottom: "0",
                      fontWeight: "normal",
                    }}
                  >
                    <input id="boots" type="checkbox" onChange={(e) => setBoots(e.target.checked)} checked={includeBoots} />
                    <span className="checkmark">
                      {includeBoots ? <Checkmark style={{ height: "13px", width: "12px" }} /> : ""}
                    </span>
                    <span style={{ marginLeft: "12px" }}>{t(`With Boots`)}</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="header-right-half" style={{ width: "32%" }}>
              <div className="title sortable" onClick={setSortBy.bind(null, "coreItemsSort", "win")}>
                <span
                  style={{
                    color: sort.coreItemsSort[0] === "win" ? "white" : "",
                    fontWeight: sort.coreItemsSort[0] === "win" ? "600" : "",
                  }}
                >
                  {t(`Win Rate`)}
                </span>
                {sort.coreItemsSort[0] === "win" && <div className="blue-bar-horz" />}
              </div>
              <div className="title sortable" onClick={setSortBy.bind(null, "coreItemsSort", "pick")}>
                <span
                  style={{
                    color: sort.coreItemsSort[0] === "pick" ? "white" : "",
                    fontWeight: sort.coreItemsSort[0] === "pick" ? "600" : "",
                  }}
                >
                  {t(`Pick Rate`)}
                </span>
                {sort.coreItemsSort[0] === "pick" && <div className="blue-bar-horz" />}
              </div>
            </div>
          </div>
          <div className="grid-block-content">
            {displayRows(includeBoots ? core_items_with_boots : core_items, coreItemsList, coreItemsMatches, true)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Items;
