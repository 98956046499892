import React, { Fragment, useState, useEffect, useRef } from "react";
import ReactSVG from "react-svg";
import { Rune } from "@outplayed/tooltips";
import RuneTree from "components/common/RuneTrees/RuneTree_2";
import RuneTreeCondensed from "components/RuneTreeCondensed";
import _round from "lodash.round";
import { PlaceholderCounters } from "components/Champions/Placeholders";
import { useTranslation } from "react-i18next";

import { getOverview } from "api/data_parser/champion_overview.js";
import { numberWithCommas } from "lib/general-helper.js";
import { getRiotAssetsContext, getNotFoundImg } from "@outplayed/riot-assets";
import { useValidatedChampionProfileParams, useChampionRecBuild } from "lib/hooks/championProfileHooks";
import { ReactComponent as ButtonDown } from "svg/button-down.svg";
import { ReactComponent as ButtonUp } from "svg/button-up.svg";

const RunesGroup = (props) => {
  const observerNode = useRef();
  const observer = useRef();
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { runes, version, totalMatches } = props;

  useEffect(() => {
    if (setShow && observer.current) {
      return observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShow(true);
        }
      },
      {
        rootMargin: "200px 0px 200px 0px",
        threshold: 0,
      },
    );

    if (observerNode.current) {
      observer.current.observe(observerNode.current);
    }

    return () => observer.current.disconnect();
  }, [setShow]);

  if (typeof window !== "undefined" && !show) {
    return <div ref={observerNode} className="grid-block-content-row condensed-runes-row"></div>;
  }

  return (
    <div
      className={`grid-block-content-row condensed-runes-row ${expanded ? "active" : ""}`}
      onClick={() => {
        setExpanded(!expanded);
      }}
    >
      <div style={{ width: "100%" }}>
        <div className="condensed-runes">
          {expanded ? <ButtonUp className={"rune-button"} /> : <ButtonDown className={"rune-button"} />}
          <div className="condensed-primary">
            <RuneTreeCondensed perkStyle={runes.primary_style} activePerks={runes.active_perks} version={version} ssr={true} />
          </div>
          <div className="condensed-sub">
            <RuneTreeCondensed perkStyle={runes.sub_style} activePerks={runes.active_perks} version={version} ssr={true} />
          </div>
          <div className="right-half-runeset-rates">
            <div className="pick-rate">
              <div>{`${_round((runes.matches / totalMatches) * 100, 2)}%`}</div>
              <div className="pick-matches">{numberWithCommas(runes.matches)}</div>
            </div>
            <div className="win-rate">{`${runes.win_rate}%`}</div>
          </div>
        </div>
        {expanded && (
          <div className="rune-trees-container" style={{ gap: "44px" }}>
            <RuneTree
              patch={version}
              primaryPerk={true}
              perkStyle={runes.primary_style}
              activePerks={runes.active_perks}
              ssr={true}
            />
            <RuneTree patch={version} perkStyle={runes.sub_style} activePerks={runes.active_perks} ssr={true} />
          </div>
        )}
      </div>
    </div>
  );
};

const Runes = (props) => {
  const [viewAll, setViewAll] = useState(false);
  const [runeSort, setRuneSort] = useState(["pick", true]);

  const { championId, championData, role } = props;

  const {
    data: builds,
    loading: fetchingBuilds,
    error: errorBuilds,
  } = useChampionRecBuild(championId, "rune-sets", { ssr: true });
  const { t } = useTranslation();

  let { validatedParams } = useValidatedChampionProfileParams(championId, "rune-sets", { keepDefaultParams: true });
  validatedParams = { ...validatedParams, role };
  const recommendedBuild = getOverview(builds, validatedParams);

  const version = validatedParams.patch;

  const displayKeystones = (array, totalMatches, version) => {
    array.sort((a, b) => b.matches - a.matches);

    return array.map((el, index) => {
      const active = !viewAll && el.keystone_id === keystone && el.sub_style === subStyle ? "active" : "";
      const { keystone_id, sub_style, win_rate, matches } = el;

      return (
        <div
          key={index}
          className={`grid-block-content-row ${active}`}
          onClick={() =>
            setKeystoneFilter(
              // null,
              false,
              keystone_id,
              sub_style,
            )
          }
        >
          <div className="keystone-pair">
            <div style={{ display: "flex" }}>
              <div className="keystone-perk">
                <Rune runeId={keystone_id} patch={version} disableTooltip ssr={true} />
              </div>
              <div className="sub-perkstyle">
                <Rune runeId={sub_style} patch={version} disableTooltip ssr={true} />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="pick-rate">
              <span>{t(`Pick Rate`)}: </span>
              <strong>{`${_round((matches / totalMatches) * 100, 2)}%`}</strong>
            </div>
            <div className="win-rate">
              <span>{`${t(`Win Rate`)}: `}</span>
              <strong>{`${win_rate}%`}</strong>
            </div>
          </div>
        </div>
      );
    });
  };

  const displayRows = (array, winMatchesIndex, pickMatchesIndex, totalMatches) => {
    array.sort((a, b) => b[pickMatchesIndex] - a[pickMatchesIndex]);

    return array.map((el, index) => {
      return (
        <div key={index} className="grid-block-content-row">
          {el[el.length - 1]}
          <div className="pick-rate">
            <div>{`${_round((el[pickMatchesIndex] / totalMatches) * 100, 2)}%`}</div>
            <div className="pick-matches">{numberWithCommas(el[pickMatchesIndex])}</div>
          </div>
          <div className="win-rate">{`${_round((el[winMatchesIndex] / el[pickMatchesIndex]) * 100, 2)}%`}</div>
        </div>
      );
    });
  };

  const setSortBy = (sortBy) => {
    setRuneSort([sortBy, runeSort[0] === sortBy ? !runeSort[1] : true]);
  };

  const sortData = (array) => {
    const sortBy = runeSort[0];
    const dir = runeSort[1];

    array.sort((a, b) => {
      if (sortBy === "pick") {
        return dir ? b.matches - a.matches : a.matches - b.matches;
      } else if (sortBy === "win") {
        return dir ? b.wins / b.matches - a.wins / a.matches : a.wins / a.matches - b.wins / b.matches;
      }
    });
  };

  const sortArrow = (sortBy) => {
    if (runeSort[0] === sortBy) {
      return (
        <ReactSVG
          wrapperClassName="sort-arrows"
          className="sorted"
          path={
            runeSort[1]
              ? "https://static.bigbrain.gg/assets/ugg/icons/arrow-down.svg"
              : "https://static.bigbrain.gg/assets/ugg/icons/arrow-up.svg"
          }
        />
      );
    } else {
      return <ReactSVG wrapperClassName="sort-arrows" path={"https://static.bigbrain.gg/assets/ugg/icons/arrows-sort.svg"} />;
    }
  };

  const [keystone, setKeystone] = useState(null);
  const [subStyle, setSubStyle] = useState(null);

  const setKeystoneFilter = (viewAll, keystone, subStyle) => {
    setViewAll(viewAll);
    setKeystone(keystone);
    setSubStyle(subStyle);
  };

  const setKeystonePair = () => {
    const keystones = recommendedBuild && recommendedBuild.keystone_pairs;
    if (keystones && keystones[0]) {
      keystones.sort((a, b) => {
        return b.matches - a.matches;
      });

      if (keystone !== keystones[0].keystone_id || subStyle !== keystones[0].sub_style) {
        setKeystone(keystones[0].keystone_id);
        setSubStyle(keystones[0].sub_style);
      }
    }
  };

  useEffect(() => {
    if (!keystone || !subStyle) {
      setKeystonePair();
    }
  }, [recommendedBuild]);

  if (fetchingBuilds) {
    return <PlaceholderCounters />;
  }

  const { keystone_pairs } = recommendedBuild || {};

  if (!recommendedBuild || errorBuilds || !keystone_pairs || !keystone_pairs[0]) {
    return (
      <div className="content-section champion-profile-error">
        <img src={getNotFoundImg()} alt="No data found" />
        No data found. Please try again or change filters.
      </div>
    );
  }

  const keystones = recommendedBuild && recommendedBuild.keystone_pairs;
  keystones.sort((a, b) => {
    return b.matches - a.matches;
  });

  const initKeystone = keystones[0].keystone_id;
  const initSubStyle = keystones[0].sub_style;

  const totalMatches = keystone_pairs.reduce((acc, curr) => acc + curr.matches, 0);

  let allRunes = [];
  keystone_pairs.forEach((set) => {
    set.runes.forEach((runes) => {
      if (viewAll && runes.matches / totalMatches < 0.01) {
        return;
      }

      if (
        !viewAll &&
        !(
          runes.active_perks.includes(Number(keystone ? keystone : initKeystone)) &&
          runes.sub_style === Number(subStyle ? subStyle : initSubStyle)
        )
      ) {
        return;
      }
      allRunes.push(runes);
    });
  });

  sortData(allRunes);

  return (
    <div className="champion-runes">
      <div className="keystone-pairs grid-block">
        <div className="grid-block-header">
          <div className="header-left-half">
            <div className="bluebar" />
            <div className="title">{t(`Keystones`)}</div>
          </div>
          <div className="header-right-half">
            <div />
            <div className="title sortable view-all" onClick={() => setKeystoneFilter(true, "", "")}>
              View All
            </div>
          </div>
        </div>
        <div className="grid-block-content">{displayKeystones(keystone_pairs, totalMatches, version)}</div>
      </div>
      <div className="runes grid-block">
        <div className="grid-block-header">
          <div className="header-left-half">
            <div className="bluebar" />
            <div className="title">
              {t(`Runes`)}
              {viewAll && (
                <i className="grey-text" style={{ marginLeft: 5 }}>
                  {"(Sets with pick rates <1% will not be shown)"}
                </i>
              )}
            </div>
          </div>
          <div className="header-right-half">
            <div className="title sortable" onClick={setSortBy.bind(null, "pick")}>
              <span
                style={{
                  color: runeSort[0] === "pick" ? "white" : "",
                  fontWeight: runeSort[0] === "pick" ? "600" : "",
                  marginRight: "-24px",
                }}
              >
                {t(`Pick Rate`)}
              </span>
              {runeSort[0] === "pick" && <div className="blue-bar-horz" style={{ marginRight: "-24px" }} />}
            </div>
            <div className="title sortable win-rate-title" onClick={setSortBy.bind(null, "win")}>
              <span style={{ color: runeSort[0] === "win" ? "white" : "", fontWeight: runeSort[0] === "win" ? "600" : "" }}>
                {t(`Win Rate`)}
              </span>
              {runeSort[0] === "win" && <div className="blue-bar-horz" />}
            </div>
          </div>
        </div>
        <div className="grid-block-content">
          {allRunes.map((runes, index) => {
            return (
              <RunesGroup key={`${keystone}-${subStyle}-${index}`} version={version} runes={runes} totalMatches={totalMatches} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Runes;
