import React from "react";
import classnames from "classnames";
import { useJSONFetcher } from "@outplayed/json-fetcher";

const CountersSEO = (props) => {
  const { className, championId, patch, displayPatch, roles } = props;
  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const { data, loading, error } = useJSONFetcher(
    `https://static.bigbrain.gg/assets/lol/riot_patch_update/${envDir}/seo-champion-names.json`,
    { ssr: true },
  );

  const roleTextMap = {
    1: "Jungle",
    2: "Support",
    3: "ADC",
    4: "Top Lane",
    5: "Mid Lane",
  };
  const season = patch.split("_")[0];
  const roleText = roles ? roleTextMap[roles[0]] : "";
  const seoNames = data && data[championId];

  if (!data || error || loading) {
    return null;
  }

  return (
    <div className={classnames("seo-fluff", className)}>
      {`Use win rate and GD15 to find the best ${roles ? roleTextMap[roles[0]] : ""} champion who counters ${
        seoNames["name"]
      }. Win Champion Select with ${
        seoNames["altName"] || seoNames["name"]
      } counters for \n LoL S${season} Patch ${displayPatch}. ${
        roles
          ? `Main Role Order: ${roleTextMap[roles[0]]} > ${roleTextMap[roles[1]]} > ${roleTextMap[roles[2]]} > ${
              roleTextMap[roles[3]]
            } > ${roleTextMap[roles[4]]}`
          : ""
      }`}
    </div>
  );
};

export default CountersSEO;
