import React from "react";
import { TooltipContainer } from "@outplayed/tooltips";

const YellowAlert = (props) => {
  const { id, label, width } = props;
  return (
    <TooltipContainer tooltipInfo={label}>
      <div className="ugg-alert">
        <img width={width} src={"https://static.bigbrain.gg/assets/ugg/icons/alert-yellow.svg"} />
      </div>
    </TooltipContainer>
  );
};

export default YellowAlert;
