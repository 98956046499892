import { window } from "global";
import React, { useEffect } from "react";
import classnames from "classnames";
import { capitalizeFirstLetter } from "lib/general-helper";
import { useLocation } from "react-router-dom";
import { useCurrentQueueType } from "lib/hooks/championProfileHooks";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { useTranslation } from "react-i18next";
import { useGlobal } from "reactn";
import { Helmet } from "react-helmet-async";
import { getSpecialBlurbType } from "lib/specialGameMode";

function capitalizeMulti(multi) {
  switch (multi) {
    case "ap":
    case "ad":
      return multi.toUpperCase();
    case "tank":
    case "crit":
    case "lethality":
      return multi.substring(0, 1).toUpperCase() + multi.substring(1);
    case "onhit":
      return "On Hit";
    case "red":
      return "Red";
    case "blue":
      return "Blue";
  }
}

const BuildSEO = (props) => {
  const { className, page, displayPatch, championId, roleText, rank, multi } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const lang = useGlobal("language");

  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const {
    data: buildChampionNames,
    loading: fetchingBuildChampionNames,
    error: errorBuildChampionNames,
  } = useJSONFetcher(`https://static.bigbrain.gg/assets/lol/riot_patch_update/${envDir}/seo-champion-names.json`, { ssr: true });
  let queueType = useCurrentQueueType(page);

  let modifiedRoleText = capitalizeFirstLetter(roleText);
  if (modifiedRoleText === "Middle") {
    modifiedRoleText = "Mid";
  }
  if (modifiedRoleText === "Adc") {
    modifiedRoleText = "ADC";
  }

  modifiedRoleText = t(modifiedRoleText);

  if (buildChampionNames) {
    let blurbType = multi ? "Mature multi" : "Mature";
    const champion = buildChampionNames[championId];
    if ("blurbType" in champion) {
      blurbType = champion["blurbType"];
    }

    const gameMode = getSpecialBlurbType(page);

    if (getSpecialBlurbType(page)) {
      blurbType = multi ? "multi special" : gameMode;
    }

    let champ1 = champion["name"];
    let champ2 = champion["name"];
    let champ3 = champion["name"];

    let blurb = t(`Build ${blurbType.toLowerCase()} blurb`, {
      champ1: champ1,
      champ2: champ2,
      champ3: champ3,
      role: modifiedRoleText,
      rank: rank,
      patch: displayPatch,
      multi: multi ? capitalizeMulti(multi) : "",
      game: gameMode,
    });

    return (
      <div className={classnames("seo-fluff", className)}>
        <Helmet>
          <meta http-equiv="content-language" content={lang} />
        </Helmet>
        {blurb}
      </div>
    );
  }
  return null;
};

export default BuildSEO;
