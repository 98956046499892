import React, { useState, useEffect } from "react";
import MediaQuery from "../../MediaQuery";
import { ReactComponent as FilterIcon } from "svg/filters-icon.svg";
import { useQueryString } from "hooks/general-hooks";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { buildQueryParams } from "lib/general-helper.js";
import { getNotFoundImg, getRiotAssetsContext } from "@outplayed/riot-assets";
import { usePageTitleAndDesc } from "../../../lib/seo_helper";
import { REGION_OPTIONS } from "../../Leaderboards/leaderboard-filters";
import { REGION_OPTIONS_ALL } from "./RegionOptions";

import LeaderboardRegionFilter from "../../Leaderboards/components/filters/LeaderboardRegionFilter";
import Champion from "../../FilterManager/Filters/Champion";
import ChampionLeaderboardTableContainer from "./ChampionLeaderboardTableContainer";
import { useGetChampionOTPStats, useGetChampionOTPStats2 } from "@ugg/shared/api/requests/champions/otp";

const FiltersRow = (props) => {
  const { currentChampId, onFilterChange } = props;
  const queryParams = useQueryString();
  const { region } = queryParams;

  return (
    <div className="leaderboard-ranking_filters-row">
      <div className="leaderboard_filters">
        <div className="filter-label">
          <FilterIcon />
          <MediaQuery min="TABLET" max="DESKTOP_LARGE">
            <span>Filters</span>
          </MediaQuery>
        </div>
        <MediaQuery min="TABLET" max="DESKTOP_LARGE">
          <LeaderboardRegionFilter value={region ? region : "world"} options={REGION_OPTIONS_ALL} className="region-filter" />
        </MediaQuery>
        <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
          <LeaderboardRegionFilter
            value={region ? region : "world"}
            options={REGION_OPTIONS_ALL}
            className="region-filter"
            isMobile
          />
        </MediaQuery>
        <Champion
          value={currentChampId}
          filterKey="championId"
          onFilterChange={onFilterChange}
          disableAllChampsOption={true}
          disableClearButton={true}
          fontSize={12}
        />
      </div>
    </div>
  );
};

const ChampionLeaderboards = (props) => {
  const { getChampionName, getChampionIdByName } = getRiotAssetsContext();
  const getPageTitleAndDesc = usePageTitleAndDesc();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryString();
  const { region, champion } = queryParams;

  const split = location.pathname.split("/lol/champion-leaderboards/");
  const champName = split && split[1];
  if (!champName) {
    history.replace({
      pathname: `/lol/champion-leaderboards/aatrox`,
    });
  }
  const currentChampId = getChampionIdByName(champName);

  const { championName, regionFullName } = props;

  const onFilterChange = (filterKey, value) => {
    const search = { ...queryParams };

    history.push({
      pathname: `/lol/champion-leaderboards/${getChampionName(value.value)}`,
      search: buildQueryParams({
        ...search,
      }),
    });
  };

  // useEffect(() => {
  //   if (
  //     !region ||
  //     !REGION_OPTIONS_ALL.find(
  //       (option) => option.value === region.toLowerCase()
  //     )
  //   ) {
  //     const search = { ...queryParams };

  //     history.replace({
  //       pathname: location.pathname,
  //       search: buildQueryParams({
  //         ...search,
  //         region: region ? region : "world",
  //       }),
  //     });
  //   }
  // }, [region]);

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, [JSON.stringify(queryParams)]);

  const {
    data: regionData,
    loading: regionLoading,
    error: regionError,
  } = useGetChampionOTPStats2(parseInt(currentChampId), region, {
    ssr: false,
  });

  const {
    data: worldData,
    loading: worldLoading,
    error: worldError,
  } = useGetChampionOTPStats(parseInt(currentChampId), {
    ssr: false,
    skip: currentChampId <= 0,
  });

  const otpWorldData = {
    players: worldData?.getChampionOtpStats.map((playerStats, i) => ({
      ...playerStats,
      overallRanking: i + 1,
    })),
  };

  const otpRegionData = {
    players: regionData?.getChampionOtpStats2.map((playerStats, i) => ({
      ...playerStats,
      overallRanking: i + 1,
    })),
  };

  // const data = region === "world" ? otpWorldData : otpRegionData;
  const data = region && region !== "world" ? otpRegionData : otpWorldData;
  const { players } = data || {};

  // console.log(data);

  const infoIcon = "https://static.bigbrain.gg/assets/lol/icons/information-blue.svg";

  let content = null;

  if (regionLoading || worldLoading) {
    content = (
      <div className="content-section leaderboard_table_loading">
        <div className="spinthatshit-loader">
          <div className="spinner"></div>
        </div>
      </div>
    );
  } else if (!players || players.length === 0 || players.length < 6) {
    content = (
      <div className="content-section leaderboard_table_error">
        <img src={getNotFoundImg()} alt="No data found" />
        <p>Sorry, there is no data available. Please try changing the filters or page.</p>
        <p>
          <Link
            to={{
              pathname: location.pathname,
              search: buildQueryParams({ region }),
            }}
          >
            Click Here
          </Link>{" "}
          to reset filters.
        </p>
      </div>
    );
  } else {
    content = (
      <ChampionLeaderboardTableContainer
        page={1}
        data={data}
        championId={currentChampId}
        totalPlayerCount={data?.players?.length}
        showRegion={region === "world" || !region}
      />
    );
  }

  return (
    <div className="champion-leaderboard">
      {getPageTitleAndDesc("champion_leaderboards", {
        champion: { name: championName },
        region: regionFullName === "World" ? "the World" : regionFullName ? regionFullName : "the World",
      })}
      <FiltersRow currentChampId={currentChampId} onFilterChange={onFilterChange} />
      {/* <div className="leaderboard-rules">
        <img src={infoIcon} alt="blue information icon" />
        <span>
          To make it onto the champion leaderboard you need to be Grandmaster+
          with at least 15 games played on {championName}
        </span>
      </div> */}
      {content}
    </div>
  );
};

export default ChampionLeaderboards;
