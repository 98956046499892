import React, { Component, useState, useMemo, useRef, useEffect } from "react";
import { useGlobal } from "reactn";
import { Link, useLocation } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getTierColor } from "lib/tierHelper";
import { numberWithCommas } from "lib/format_helper.js";
import classnames from "classnames";
import { useTable, useSortBy, usePagination } from "react-table";
import NoData from "./NoData";
import { TooltipContainer } from "@outplayed/tooltips";
import ChampionCounter from "components/Tooltips/ChampionCounter";
import * as generalHelper from "lib/general-helper.js";
import { useValidateQueryParams } from "query_parameters/paramsHelper";
import { useQueryString } from "hooks/general-hooks";
import { getForcedParams } from "lib/specialGameMode";
import { isSpecialGameModeByQueueType, getSpecialGameModeTierListSettings } from "lib/specialGameMode";

const MATCHUP_PAGE_SIZE = 70;

function getCounterHeader(dataFor) {
  return (
    <TooltipContainer tooltipInfo={`Duo's ${dataFor} Difference at 15 minutes`}>
      <div>{dataFor} @15</div>
    </TooltipContainer>
  );
}

const CountersWinRate = (props) => {
  const { winRate, championId, data } = props;

  const params = {
    curChampionId: data.champion_id,
    oppChampionId: championId,
    datum: Object.assign({}, data, { win_rate: 100 - data.winrate }),
  };

  return (
    <ChampionCounter data={params}>
      <div className={getTierColor(winRate)}>
        <b>{winRate.toFixed(2) + "%"}</b>
      </div>
    </ChampionCounter>
  );
};

const CounterChampionName = (props) => {
  const { getChampionName, getChampionImgFromSprite, getNormalizedChampionName } = getRiotAssetsContext();
  const { championId, oppChampionId, data } = props;
  const params = {
    curChampionId: oppChampionId,
    oppChampionId: championId,
    datum: Object.assign({}, data, { win_rate: 100 - data.winrate }),
  };
  const location = useLocation();
  const search = location.search.substring(1);
  const filters = generalHelper.parseQueryString(search);
  delete filters.opp;

  return (
    <ChampionCounter data={params}>
      <Link
        className="champion-played gtm-counters-champion"
        to={{
          pathname: `/lol/champions/${getNormalizedChampionName(oppChampionId)}/build`,
          search: `?${generalHelper.buildQueryParams(filters)}`,
        }}
      >
        <div className="champion-icon">{getChampionImgFromSprite(oppChampionId, { size: 30 })}</div>
        <div className="champion-name">
          <strong>{getChampionName(oppChampionId)}</strong>
        </div>
      </Link>
    </ChampionCounter>
  );
};

const CountersTable = (props) => {
  const { getChampionName } = getRiotAssetsContext();
  const prevSort = useRef(null);
  const [windowBreakpoint] = useGlobal("responsive");
  const validateQueryParams = useValidateQueryParams();
  const location = useLocation();

  let { data, championId, role } = props;

  if (!data || data.length == 0) {
    return <NoData />;
  }

  let queryParams = useQueryString();
  queryParams = validateQueryParams({
    queryParams,
    page: "matchups",
    keepDefaultParams: true,
  });

  const forcedParams = getForcedParams(location);
  queryParams = { ...queryParams, ...forcedParams, role };

  const isMobile =
    windowBreakpoint === "MOBILE_SMALL" || windowBreakpoint === "MOBILE_MEDIUM" || windowBreakpoint === "MOBILE_LARGE";

  // phone size
  const isMobileSmall = windowBreakpoint === "MOBILE_SMALL" || windowBreakpoint === "MOBILE_MEDIUM";

  const DESKTOP_WIDTH = 110;
  const columns = useMemo(
    () => [
      {
        id: "rank",
        Header: !isMobileSmall ? "Rank" : "",
        headerClassName: "rank",
        className: "rank",
        Cell: (props) => <span>{props.index + 1}</span>,
        sortable: false,
        width: !isMobileSmall ? 60 : 30,
        minWidth: !isMobileSmall ? 40 : 30,
        maxWidth: !isMobileSmall ? 60 : 30,
      },
      {
        Header: !isMobileSmall ? "Champion" : "",
        accessor: "champion_id",
        headerClassName: "champion",
        className: "champion",
        sortable: true,
        minWidth: !isMobileSmall ? 140 : 40,
        maxWidth: !isMobileSmall ? undefined : 40,

        sortType: (a, b) => {
          const champNameA = getChampionName(a.values.champion_id);
          const champNameB = getChampionName(b.values.champion_id);
          return champNameB.localeCompare(champNameA);
        },
        Cell: (props) => <CounterChampionName oppChampionId={props.value} data={props.cell.row.values} championId={championId} />,
      },
      {
        Header: "Win Rate",
        accessor: "win_rate",
        headerClassName: "win_rate",
        className: "win_rate",
        sortable: true,
        minWidth: !isMobileSmall ? 65 : 65,
        maxWidth: !isMobileSmall ? 73 : undefined,
        Cell: (props) => <CountersWinRate winRate={props.value} data={props.cell.row.values} championId={championId} />,

        id: "winrate",
        sortDescFirst: true,
        sortType: (a, b) => {
          return a.values.winrate - b.values.winrate;
        },
      },
      {
        Header: !isMobile ? getCounterHeader("± Gold") : getCounterHeader("Gold"),
        accessor: "gold_adv_15",
        headerClassName: "gold",
        className: "gold",
        sortable: true,
        minWidth: !isMobileSmall ? 80 : 70,
        maxWidth: !isMobileSmall ? DESKTOP_WIDTH : 70,
        sortType: (a, b) => {
          return a.values.gold_adv_15 - b.values.gold_adv_15;
        },
      },
      {
        Header: !isMobile ? getCounterHeader("± XP") : getCounterHeader("XP"),
        accessor: "xp_adv_15",
        headerClassName: "xp",
        className: "xp",
        sortable: true,
        minWidth: !isMobileSmall ? 80 : 60,
        maxWidth: !isMobileSmall ? DESKTOP_WIDTH : 70,
        sortType: (a, b) => {
          return a.values.xp_adv_15 - b.values.xp_adv_15;
        },
      },
      {
        Header: !isMobile ? getCounterHeader("± Kills") : getCounterHeader("Kills"),
        accessor: "kill_adv_15",
        headerClassName: "kill",
        className: "kill",
        sortable: true,
        minWidth: !isMobileSmall ? 80 : 60,
        maxWidth: !isMobileSmall ? DESKTOP_WIDTH : 70,
        sortType: (a, b) => {
          return a.values.kill_adv_15 - b.values.kill_adv_15;
        },
      },
      {
        Header: !isMobile ? getCounterHeader("± CS") : getCounterHeader("CS"),
        accessor: "cs_adv_15",
        headerClassName: "cs",
        className: "cs",
        sortable: true,
        minWidth: !isMobileSmall ? 80 : 60,
        maxWidth: !isMobileSmall ? DESKTOP_WIDTH : 70,
        sortType: (a, b) => {
          return a.values.cs_adv_15 - b.values.cs_adv_15;
        },
      },
      {
        Header: !isMobile ? getCounterHeader("± Jun CS") : getCounterHeader("Jun CS"),
        accessor: "jungle_cs_adv_15",
        headerClassName: "jungle_cs",
        className: "jungle_cs",
        sortable: true,
        minWidth: !isMobileSmall ? 65 : 85,
        maxWidth: !isMobileSmall ? DESKTOP_WIDTH : undefined,
        sortType: (a, b) => {
          return a.values.jungle_cs_adv_15 - b.values.jungle_cs_adv_15;
        },
      },
      {
        Header: "Matches",
        accessor: "matches",
        headerClassName: "matches",
        className: "matches",
        minWidth: !isMobileSmall ? 80 : 60,
        maxWidth: !isMobileSmall ? 117 : undefined,
        sortDescFirst: true,
        Cell: ({ cell: { value } }) => <span>{numberWithCommas(value)}</span>,
      },
    ],
    [windowBreakpoint],
  );

  const getMaxWidth = (queryParams, column) => {
    let maxWidth = column.totalMaxWidth;
    if (isSpecialGameModeByQueueType(queryParams.queueType)) {
      maxWidth = undefined;
    }
    return maxWidth;
  };

  const columnStyle = (queryParams, column) => ({
    minWidth: column.totalMinWidth,
    maxWidth: getMaxWidth(queryParams, column),
    flex: `1 1 100%`,
  });

  const {
    state,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    preSortedRows,
    prepareRow,
    setHiddenColumns,
    setPageSize,
  } = useTable(
    {
      columns,
      data: data,
      initialState: {
        pageSize: MATCHUP_PAGE_SIZE,
        pageIndex: 0,
        sortBy: prevSort.current || [{ id: "winrate", desc: true }],
      },
      disableSortRemove: true,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    setPageSize(MATCHUP_PAGE_SIZE);
  }, [windowBreakpoint]);

  useEffect(() => {
    if (prevSort.current) {
      if (prevSort.current[0].id !== state.sortBy[0].id || prevSort.current[0].desc !== state.sortBy[0].desc) {
        prevSort.current = state.sortBy;
        setPageSize(MATCHUP_PAGE_SIZE);
      }
    } else {
      prevSort.current = state.sortBy;
    }
  }, [state.sortBy]);

  return (
    <div
      {...getTableProps({
        className: "content-section ReactTable ugg-table-2 matchups-table scrollable-table",
      })}
    >
      <div className={"rt-thead -header"}>
        {headerGroups.map((headerGroup, index) => (
          <div key={index} {...headerGroup.getHeaderGroupProps({ className: "rt-tr" })}>
            {headerGroup.headers.map((column, index) => {
              const thClassNames = classnames([
                "rt-th",
                column.headerClassName,
                column.isSorted && !column.isSortedDesc ? "-sort-asc" : "",
                column.isSorted && column.isSortedDesc ? "-sort-desc" : "",
              ]);

              return (
                <div
                  key={index}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      className: thClassNames,
                      style: columnStyle(queryParams, column),
                    }),
                  )}
                >
                  {column.render("Header")}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps({ className: "rt-tbody" })}>
        {page.map((row, i) => {
          prepareRow(row);
          const { values } = row;

          return (
            <div key={i} className="rt-tr-group">
              <div {...row.getRowProps({ className: "rt-tr" })}>
                {row.cells.map((cell, index) => {
                  const tdClassNames = classnames("rt-td", cell.column.className, {
                    sorted: cell.column.isSorted,
                    "is-in-odd-row": i % 2 === 0,
                  });

                  return (
                    <div
                      key={index}
                      {...cell.getCellProps({
                        className: tdClassNames,
                        style: columnStyle(queryParams, cell.column),
                      })}
                    >
                      {cell.render("Cell", { index: i })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CountersTable;
