import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getSpecialGameModePaths } from "lib/specialGameMode";
import MediaQuery from "components/MediaQuery";
import { useActiveSpecialModes } from "@ugg/shared/utils/special-game-modes";
import { useGlobal } from "reactn";
import { useParams } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { PageNav } from "@ugg/shared/components/common/PageNav";

const ChampionPageNav = (props) => {
  const { t } = useTranslation();
  const { championKey } = props;
  const [language] = useGlobal("language");
  const params = useParams();
  const { getChampionIdByName } = getRiotAssetsContext();

  let normalizedChampionName = championKey.toLowerCase();
  if (normalizedChampionName === "monkeyking") {
    normalizedChampionName = "wukong";
  }

  const specialKayn = getChampionIdByName(normalizedChampionName) === 141 ? "red/" : "";
  let rootPathname = `/lol/champions/${normalizedChampionName}`;
  let languageBuildPathname =
    language !== "en_US"
      ? `/lol/${language.toLowerCase()}/champions/${specialKayn}${normalizedChampionName}`
      : `/lol/champions/${specialKayn}${normalizedChampionName}`;

  let multiBuildPathname = `/lol/champions/${params.multi}/${normalizedChampionName}`;

  const liveSpecialModes = useActiveSpecialModes({ isChampBuildPage: true, ssr: true });

  // Temporary fix to remove urf from the liveSpecialModes
  //
  // if (liveSpecialModes.includes("urf")) {
  //   const index = liveSpecialModes.indexOf("urf")
  //   if (index > -1) {
  //     liveSpecialModes.splice(index, 1)
  //   }
  // }

  const specialGameModePaths = [];
  const paths = getSpecialGameModePaths(normalizedChampionName, liveSpecialModes);

  const multis = ["ap/", "tank/", "ad/", "crit/", "lethality/", "onhit/", "red/", "blue/"];
  for (let i = 0; i < paths.length; i++) {
    paths[i].label = t(paths[i].label);
    //Depending how we add builds in the future, this is only ap for now
    //We could add each one ourselves or use the multi param that's here
    const URL = paths[i].activePaths[0];
    const CHAMP_INDEX = paths[i].activePaths[0].indexOf(normalizedChampionName);
    for (const multi in multis) {
      paths[i].activePaths.push(URL.slice(0, CHAMP_INDEX) + multis[multi] + URL.slice(CHAMP_INDEX));
    }

    specialGameModePaths.push(paths[i]);
  }

  const tabs = [
    {
      path: `${languageBuildPathname}/build`,
      label: t("Build"),
      activePaths: [`${languageBuildPathname}/build`, new RegExp(`${rootPathname}\/.+-build`), `${multiBuildPathname}`],
    },
    ...specialGameModePaths,
    { path: `${rootPathname}/counter`, label: t("Counters") },
    {
      path: `/lol/champion-leaderboards/${normalizedChampionName}`,
      label: t("Leaderboards"),
    },
    {
      customComponent: (
        <a
          className="pbs-tab flex items-center"
          target="_blank"
          href={`https://probuildstats.com/champion/${normalizedChampionName}`}
          rel="noreferrer"
        >
          <img
            className="pbs-logo inline-flex relative top-[1px] w-[13px] mr-[10px]"
            src="https://static.bigbrain.gg/assets/probuildstats/logos/pbs-logo.svg"
            alt="ProbuildStats"
          />
          {t("Pro Builds")}
        </a>
      ),
    },
    {
      label: t("More Stats"),
      // new: true,
      dropdown: [
        { path: `${rootPathname}/matchups`, label: t("Matchups") },
        { path: `${rootPathname}/items`, label: t("Items") },
        { path: `${rootPathname}/item-paths`, label: t("Build Paths") },
        { path: `${rootPathname}/runes-table`, label: t("Runes Table") },
        { path: `${rootPathname}/rune-sets`, label: t("Rune Sets") },
        {
          path: `${rootPathname}/spells-abilities`,
          label: t("Spells & Abilities"),
        },
        { path: `${rootPathname}/duos`, label: t("Duos") },
      ],
    },
  ];

  const mobileTabs = [
    { path: `${rootPathname}/build`, label: t("Build") },
    ...specialGameModePaths,
    { path: `${rootPathname}/counter`, label: t("Counters") },
    {
      path: `/lol/champions-leaderboards`,
      label: t("Leaderboards"),
      queryParams: `?champion=${normalizedChampionName}`,
    },
    {
      customComponent: (
        <a
          className="pbs-tab flex"
          target="_blank"
          href={`https://probuildstats.com/champion/${normalizedChampionName}`}
          rel="noreferrer"
        >
          <img
            className="pbs-logo inline-flex relative top-[1px] w-[13px] mr-[10px]"
            src="https://static.bigbrain.gg/assets/probuildstats/logos/pbs-logo.svg"
            alt="ProbuildStats"
          />
          {t("Pro Builds")}
        </a>
      ),
    },
  ];

  return (
    <div className="champion-page-nav">
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
        <PageNav tabs={mobileTabs} />
      </MediaQuery>
      <MediaQuery min="TABLET" max="DESKTOP_LARGE">
        <PageNav tabs={tabs} />
      </MediaQuery>
    </div>
  );
};

export default ChampionPageNav;
