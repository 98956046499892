import React, { useEffect, useState } from "react";
import { Rune } from "@outplayed/tooltips";
import { getRiotAssetsContext, getNotFoundImg } from "@outplayed/riot-assets";
import { useGlobal } from "reactn";

const GridBlock = (props) => {
  const { classNames, pathName, keystonesData, runesData, runesStatic, patch, ssr } = props;

  const keystones = runesStatic[0]?.runes;
  const otherRunes = runesStatic.slice(1);

  const displayRune = (runeId, data) => {
    const runeData = data[runeId];

    const winRate = runeData ? `${runeData.win_rate.toFixed(2)}%` : "-";
    const pickRate = runeData ? `${runeData.pick_rate.toFixed(2)}%` : "-";

    // const wtier = ""//runeData && runeData.win_rate > 53 && runeData.pick_rate >= 1 ? "great-tier" : "";
    // const ptier = ""//runeData && runeData.pick_rate > 50 ? "volxd-tier" : "";

    const grayscale = !runeData || (runeData && runeData.pick_rate < 1.0) ? "grayscale" : "";
    const topPickRate = runeData && runeData.pick_rate >= 50 ? "volxd-tier" : "";

    return (
      <div className="rune" key={runeId}>
        <div className={grayscale}>
          <Rune runeId={runeId} patch={patch} ssr={ssr} />
        </div>
        <div className={`win-rate ${grayscale}`}>{winRate}</div>
        <div className={`pick-rate ${grayscale} ${topPickRate}`}>{pickRate}</div>
      </div>
    );
  };

  return (
    <div className={`grid-block ${classNames || ""}`}>
      <div className="grid-block-header">
        <div className="bluebar" />
        <div className="title">
          <h2>{pathName}</h2>
          <span>{keystonesData ? "Primary" : "Secondary"}</span>
        </div>
      </div>
      <div className="grid-block-content">
        {keystonesData ? (
          <div className="grid-block-content-keystones">
            {keystones?.map((keystone) => displayRune(keystone.id, keystonesData))}
          </div>
        ) : null}
        {otherRunes.map((set, index) => (
          <div className="grid-block-content-runes" key={index}>
            {set.runes.map((rune) => displayRune(rune.id, runesData))}
          </div>
        ))}
      </div>
    </div>
  );
};

const RunesTable = (props) => {
  const { useRiotRunes } = getRiotAssetsContext();
  const { championRunes, patch } = props;

  const ssr = true;
  const [language] = useGlobal("language");
  const { data: runesList, loading, error } = useRiotRunes({ patch, ssr, language });

  const { keystone, primary, secondary } = championRunes || {};

  if (!championRunes) {
    return (
      <div className="content-section champion-profile-error">
        <img src={getNotFoundImg()} alt="No data found" />
        No data found. Please try again or change filters.
      </div>
    );
  }

  // each rune tree (i.e: Precision, Domination...)
  const pathOrder = [8000, 8100, 8200, 8400, 8300];

  return (
    <div className="runes-table-container">
      <div className="primary-runes-grid">
        {runesList &&
          pathOrder.map((pathId, index) => {
            const path = runesList.find((rune) => rune.id === pathId);
            return (
              <GridBlock
                key={index}
                pathName={path.name}
                keystonesData={keystone}
                runesData={primary}
                runesStatic={path.slots}
                patch={patch}
                ssr
              />
            );
          })}
      </div>
      <div className="secondary-runes-grid">
        {runesList &&
          pathOrder.map((pathId, index) => {
            const path = runesList.find((rune) => rune.id === pathId);
            return (
              <GridBlock key={index} pathName={path.name} runesData={secondary} runesStatic={path.slots} patch={patch} ssr />
            );
          })}
      </div>
    </div>
  );
};

export default RunesTable;
