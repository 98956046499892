import React, { useMemo } from "react";
import { useGlobal } from "reactn";
import LeaderboardTopFive from "../../Leaderboards/components/table/LeaderboardTopFive";
import LeaderboardTable from "../../Leaderboards/components/table/LeaderboardTable";
import { useQueryString } from "hooks/general-hooks";
import { useTable, useSortBy, usePagination } from "react-table";
import { buildQueryParams, numberWithCommas, getWinRateColor } from "lib/general-helper";
import { getProfileOverviewUrl } from "@ugg/shared/routes/app-routes";

const ChampionLeaderboardTableContainer = (props) => {
  const { page, data, championId, showRegion } = props;
  const [windowBreakpoint] = useGlobal("responsive");
  const queryParams = useQueryString();
  const { region } = queryParams;
  // console.log(data)

  const { players, topPlayerMostPlayedChamp, totalPlayerCount } = data || {};
  const isMobileSmall = ["MOBILE_MEDIUM", "MOBILE_SMALL"].includes(windowBreakpoint);
  const isMobile = ["MOBILE_LARGE", "MOBILE_MEDIUM", "MOBILE_SMALL"].includes(windowBreakpoint);

  const tableData = useMemo(() => {
    const currentData = (players || []).map((player) => {
      const { winRate, gamesPlayed, regionId } = player || {};
      // const totalGamesPlayed =
      //   parseInt(player.wins || 0) + parseInt(player.losses || 0);
      // const winRate = Math.round(
      //   (parseInt(player.wins || 0) / totalGamesPlayed) * 100
      // );
      const wins = Math.round(winRate * gamesPlayed);
      const losses = gamesPlayed - wins;
      const roundedWinRate = Math.round(winRate * 100);
      const winRateColor =
        getWinRateColor(roundedWinRate).toLowerCase() === "#ffffff" ? "#cddcfe" : getWinRateColor(roundedWinRate);
      const profileURL = getProfileOverviewUrl(
        region && region !== "world" ? region : regionId,
        player.riotUserName,
        player.riotTagLine,
      );

      return {
        ...player,
        profileURL,
        totalGamesPlayed: gamesPlayed,
        winRate: roundedWinRate,
        wins,
        losses,
        winRateColor,
      };
    });

    const topFive = page === 1 ? currentData.slice(0, 5) : [];
    const remainingData = page === 1 ? currentData.slice(5) : currentData;
    const firstEntryRanking = (remainingData[0] && remainingData[0].overallRanking) || 0;
    const lastEntryRanking =
      (remainingData[remainingData.length - 1] && remainingData[remainingData.length - 1].overallRanking) || 0;

    // console.log(topFive, remainingData, firstEntryRanking, lastEntryRanking);
    return { topFive, remainingData, firstEntryRanking, lastEntryRanking };
  }, [page, data]);

  return (
    <div className="leaderboard_table-container">
      {page === 1 && tableData.topFive.length === 5 && (
        <LeaderboardTopFive data={tableData.topFive} topPlayerMostPlayedChamp={championId} showRegion={showRegion} />
      )}
      <LeaderboardTable
        page={page}
        totalCount={totalPlayerCount}
        tableData={tableData}
        hidePagination={true}
        showRegion={showRegion}
      />
    </div>
  );
};

export default ChampionLeaderboardTableContainer;
