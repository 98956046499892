import React from "react";
import { ChampionSkill } from "@outplayed/tooltips";
import { useJSONFetcher } from "@outplayed/json-fetcher";

const CounterTips = (props) => {
  const { championId, championName } = props;

  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const {
    data: counterTips,
    loading: loadingJson,
    error: errorJson,
  } = useJSONFetcher(`https://static.bigbrain.gg/assets/lol/riot_patch_update/${envDir}/ugg/counter-tips.json`, { ssr: true });

  const parseText = (text, index) => {
    if (!text) return [];

    for (let i = 0; i < text.length; i++) {
      if (text.charAt(i) === "{") {
        if (i !== 0) {
          return [text.slice(0, i)].concat(parseText(text.slice(i), index + 1));
        }
      } else if (text.charAt(i) === "}") {
        const value = text.slice(1, i);
        const skillMap = { Q: 0, W: 1, E: 2, R: 3 };
        const inlineSkill = (
          <ChampionSkill key={index} championId={championId} skillSlot={skillMap[value]} isPassive={value === "P"} ssr>
            {({ imgSrc, skillName, tooltip }) => (
              <div className="inline-skill">
                <img src={imgSrc} alt={skillName} />
                <div className={`skill-value skill-value_${value}`}>{value}</div>
              </div>
            )}
          </ChampionSkill>
        );
        return [inlineSkill].concat(parseText(text.slice(i + 1), index + 1));
      }
    }

    return [text];
  };

  const { adv, disadv } = counterTips[championId] || {};
  return (
    <div className="counter-tips">
      <div className="free-header">
        <div>Playing vs {championName}</div>
        <div className="read-length">1 min read</div>
      </div>
      <div className="tips-container">
        {adv && adv.length > 0 && (
          <div className="tip-section">
            <div className="tip-section_header">Winning Matchup</div>
            {adv.map((text, index) => (
              <div key={index} className="tip-item">
                {parseText(text, 0)}
              </div>
            ))}
          </div>
        )}
        {disadv && disadv.length > 0 && (
          <div className="tip-section">
            <div className="tip-section_header">Losing Matchup</div>
            {disadv.map((text, index) => (
              <div key={index} className="tip-item">
                {parseText(text, 0)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CounterTips;
