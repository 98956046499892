import React, { Fragment } from "react";

const GridBlock = (props) => {
  const { classNames, headerStep, title } = props;

  return (
    <div className={`grid-block ${classNames}`}>
      <div className="grid-block-header">
        <div className="title">
          {headerStep ? (
            <Fragment>
              <span className="header-step">{headerStep}</span>
              &nbsp;
              <span className="forward-slash">/</span>
              &nbsp;
            </Fragment>
          ) : null}
          <h2>{title}</h2>
        </div>
      </div>
      <div className="grid-block-content"></div>
    </div>
  );
};

export const PlaceholderProfileFilters = () => {
  return <div className="profile-filters-placeholder"></div>;
};

export const PlaceholderRecommendedBuild = () => {
  return (
    <div className="recommended-build-placeholder">
      <GridBlock classNames="runes" title="Recommended Runes" />
      <GridBlock classNames="summoner-spells" title="Recommended Summoners" />
      <GridBlock classNames="starting-items" headerStep={1} title="Starting Build" />
      <GridBlock classNames="final-items" headerStep={2} title="Core Build" />
      <GridBlock classNames="skills" title="Recommended Abilities" />
      <GridBlock classNames="item-options-1" headerStep={3} title="Fourth Item Options" />

      <GridBlock classNames="item-options-2" headerStep={4} title="Fifth Item Options" />
      <GridBlock classNames="item-options-3" headerStep={5} title="Sixth Item Options" />
    </div>
  );
};

export const PlaceholderCounters = () => {
  return <div className="counters-placeholder"></div>;
};

export const PlaceholderTierList = () => {
  return <div className="tier-list-placeholder"></div>;
};
