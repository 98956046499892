import React, { useEffect, useMemo } from "react";
import { useGlobal } from "reactn";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import * as generalHelper from "lib/general-helper.js";
import { usePageTitleAndDesc } from "lib/seo_helper.js";
import { generateBuildAndRunes } from "lib/FilterPreferences/QueryParams";
import { GET_USER_STATE } from "@ugg/shared/api/requests/accounts/user-state";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useTranslation } from "react-i18next";

const ChampionsFacePage = (props) => {
  const { getNormalizedChampionName, getChampionImg, useChampionMini } = getRiotAssetsContext();
  const { t } = useTranslation();
  const location = useLocation();
  const [language] = useGlobal("language");
  const getPageTitleAndDesc = usePageTitleAndDesc();
  const [windowBreakpoint] = useGlobal("responsive");
  const [loggingIn] = useGlobal("loggingIn");
  const { data: userState } = useQuery(GET_USER_STATE);
  const { filterDefaults, premium } = (userState && userState.getState) || {};
  const { isPremium } = premium || {};
  const { data: championMini, loading: loadingChampionMini } = useChampionMini();

  const sortedChampions = useMemo(() => {
    return Object.values(championMini || {})
      .map((champion) => ({
        name: champion.name,
        id: champion.key,
      }))
      .sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
  }, [championMini]);

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, []);

  const displayChampions = () => {
    const filterParams = generateBuildAndRunes(filterDefaults);
    return sortedChampions.map((champion) => {
      const simplifiedChampionName = getNormalizedChampionName(champion.id);

      return champion.id == 141 ? (
        <Link
          className="champion-link"
          key={champion.id}
          to={
            language !== "en_US"
              ? `/lol/${language.toLowerCase()}/champions/red/${simplifiedChampionName}/build${filterParams}`
              : `/lol/champions/red/${simplifiedChampionName}/build${filterParams}`
          }
        >
          <div className="image-wrapper">
            <img style={{ width: "100%" }} src={getChampionImg(champion.id)} alt={champion.name} />
          </div>
          <div className="champion-name">{champion.name}</div>
        </Link>
      ) : (
        <Link
          className="champion-link"
          key={champion.id}
          to={
            language !== "en_US"
              ? `/lol/${language.toLowerCase()}/champions/${simplifiedChampionName}/build${filterParams}`
              : `/lol/champions/${simplifiedChampionName}/build${filterParams}`
          }
        >
          <div className="image-wrapper">
            <img style={{ width: "100%" }} src={getChampionImg(champion.id)} alt={champion.name} />
          </div>
          <div className="champion-name">{champion.name}</div>
        </Link>
      );
    });
  };

  return (
    <div className="champion-home">
      {getPageTitleAndDesc("champions")}
      <div className="champions-container">{displayChampions()}</div>
    </div>
  );
};

export default ChampionsFacePage;
