import React, { Fragment, useState } from "react";
import ReactSVG from "react-svg";
import _round from "lodash.round";
import { SummonerSpell } from "@outplayed/tooltips";
import { getOverview } from "api/data_parser/champion_overview.js";
import { numberWithCommas } from "lib/general-helper.js";
import * as championsHelper from "components/Champions/championsHelper.js";
import { getNotFoundImg } from "@outplayed/riot-assets";
import { useValidatedChampionProfileParams, useChampionRecBuild } from "lib/hooks/championProfileHooks";

const SpellsAbilities = (props) => {
  const [sort, setSort] = useState({
    ssSort: ["pick", true], // or "win"
    abilitiesSort: ["pick", true], // or "win"
  });

  const setSortBy = (sortContent, sortBy) => {
    setSort({
      ...sort,
      [sortContent]: [sortBy, sort[sortContent][0] === sortBy ? !sort[sortContent][1] : true],
    });
  };

  const displayRows = (array, content, totalMatches) => {
    return array.map((el, index) => {
      return (
        <div key={index} className="grid-block-content-row">
          {content[index]}
          <div className="content-row-right-half">
            <div className="win-rate">{`${_round((el.wins / el.matches) * 100, 2)}% `}</div>
            <div className="pick-rate">
              <div>{`${_round((el.matches / totalMatches) * 100, 2)}% `}</div>
              <div className="pick-matches">{numberWithCommas(el.matches)}</div>
            </div>
          </div>
        </div>
      );
    });
  };

  const sortData = (array, contentType) => {
    const list = sort[contentType];
    const sortBy = list[0];
    const dir = list[1];

    array.sort((a, b) => {
      if (sortBy === "pick") {
        return dir ? b.matches - a.matches : a.matches - b.matches;
      } else if (sortBy === "win") {
        return dir ? b.wins / b.matches - a.wins / a.matches : a.wins / a.matches - b.wins / b.matches;
      }
    });
  };

  const sortArrow = (sortContent, sortBy) => {
    if (sort[sortContent][0] === sortBy) {
      return (
        <ReactSVG
          wrapperClassName="sort-arrows"
          className="sorted"
          path={
            sort[sortContent][1]
              ? "https://static.bigbrain.gg/assets/ugg/icons/arrow-down.svg"
              : "https://static.bigbrain.gg/assets/ugg/icons/arrow-up.svg"
          }
        />
      );
    } else {
      return <ReactSVG wrapperClassName="sort-arrows" path={"https://static.bigbrain.gg/assets/ugg/icons/arrows-sort.svg"} />;
    }
  };

  const { championId, championData, role } = props;

  const {
    data: builds,
    loading: fetchingBuilds,
    error: errorBuilds,
  } = useChampionRecBuild(championId, "spells-abilities", { ssr: true });

  let { validatedParams } = useValidatedChampionProfileParams(championId, "spells-abilities", { keepDefaultParams: true });
  validatedParams = { ...validatedParams, role };
  const recommendedBuild = getOverview(builds, validatedParams);

  if (fetchingBuilds) {
    return <div className="champion-profile-placeholder basic-placeholder shimmer-placeholder" />;
  }

  const { summoner_spells, skills } = recommendedBuild || {};

  if (!recommendedBuild || errorBuilds || (!summoner_spells && !skills)) {
    return (
      <div className="content-section champion-profile-error">
        <img src={getNotFoundImg()} alt="No data found" />
        No data found. Please try again or change filters.
      </div>
    );
  }

  let summonerSpellsMatches = 0,
    skillsMatches = 0;

  sortData(summoner_spells, "ssSort");
  sortData(skills, "abilitiesSort");

  const summonerSpellsList = summoner_spells.map((set, index) => {
    summonerSpellsMatches += set.matches;
    return (
      <div key={index} className="ss-pair">
        <div className="image-wrapper">
          <SummonerSpell spellId={set.items[0]} ssr />
        </div>
        <div className="image-wrapper">
          <SummonerSpell spellId={set.items[1]} ssr />
        </div>
      </div>
    );
  });

  const skillsList = skills.map((set) => {
    skillsMatches += set.matches;
    return championsHelper.displaySkills(set.items, championId, true);
  });

  return (
    <div className="champion-spells-abilities">
      <div className="summoner-spells grid-block">
        <div className="grid-block-header">
          <div className="header-left-half">
            <div className="bluebar" />
            <div className="title">Summoner Spells</div>
          </div>
          <div className="header-right-half">
            <div className="title sortable" onClick={setSortBy.bind(null, "ssSort", "win")}>
              <span style={{ color: sort.ssSort[0] === "win" ? "white" : "", fontWeight: sort.ssSort[0] === "win" ? "600" : "" }}>
                Win Rate
              </span>
              {sort.ssSort[0] === "win" && <div className="blue-bar-horz" />}
            </div>
            <div className="title sortable" onClick={setSortBy.bind(null, "ssSort", "pick")}>
              <span
                style={{ color: sort.ssSort[0] === "pick" ? "white" : "", fontWeight: sort.ssSort[0] === "pick" ? "600" : "" }}
              >
                Pick Rate
              </span>
              {sort.ssSort[0] === "pick" && <div className="blue-bar-horz" />}
            </div>
          </div>
        </div>
        <div className="grid-block-content">{displayRows(summoner_spells, summonerSpellsList, summonerSpellsMatches)}</div>
      </div>
      <div className="abilities grid-block">
        <div className="grid-block-header">
          <div className="header-left-half">
            <div className="bluebar" />
            <div className="title">Abilities</div>
          </div>
          <div className="header-right-half">
            <div className="title sortable" onClick={setSortBy.bind(null, "abilitiesSort", "win")}>
              <span
                style={{
                  color: sort.abilitiesSort[0] === "win" ? "white" : "",
                  fontWeight: sort.abilitiesSort[0] === "win" ? "600" : "",
                }}
              >
                Win Rate
              </span>
              {sort.abilitiesSort[0] === "win" && <div className="blue-bar-horz" />}
            </div>
            <div className="title sortable" onClick={setSortBy.bind(null, "abilitiesSort", "pick")}>
              <span
                style={{
                  color: sort.abilitiesSort[0] === "pick" ? "white" : "",
                  fontWeight: sort.abilitiesSort[0] === "pick" ? "600" : "",
                }}
              >
                Pick Rate
              </span>
              {sort.abilitiesSort[0] === "pick" && <div className="blue-bar-horz" />}
            </div>
          </div>
        </div>
        <div className="grid-block-content">{displayRows(skills, skillsList, skillsMatches)}</div>
      </div>
    </div>
  );
};

export default SpellsAbilities;
