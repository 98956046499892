import React, { Fragment } from "react";
import { ChampionSkill, Item } from "@outplayed/tooltips";
import ReactSVG from "react-svg";
import { skillMapping } from "lib/general-helper.js";
import { ReactComponent as NewPathArrow } from "../../svg/new-path-arrow.svg";

export function stackDuplicateItems(items = []) {
  if (!Array.isArray(items)) {
    return [];
  }

  return items.reduce((acc, cur, index) => {
    const dupeIndex = acc.findIndex((item) => cur === item.itemId);
    if (dupeIndex < 0) {
      // New item to list
      let newItem = {};
      newItem.itemId = cur;
      newItem.count = 1;
      acc.push({ ...newItem });
    } else {
      // Update count
      acc[dupeIndex].count++;
    }

    return acc;
  }, []);
}

export function displayItems(items, pathArrows, errorMsg, patch, spriteSize, ssr) {
  if (!items || (items.length === 1 && items[0] === 0)) {
    return <div className="item-error-msg">{errorMsg}</div>;
  }

  const stackedItems = stackDuplicateItems(items);

  const list = stackedItems.map((item, index) => {
    return (
      <Fragment key={index}>
        <div className="image-wrapper">
          <Item className="sprite" itemId={item.itemId} spriteSize={spriteSize} isSprite patch={patch} ssr={ssr} />
          {item.count > 1 ? <div className="label">{item.count}</div> : null}
        </div>
        {pathArrows && index !== stackedItems.length - 1 && (
          // <img className="path-arrow" src={"https://static.bigbrain.gg/assets/ugg/icons/chevron-right-white.svg"} alt="Next Item Arrow" />
          <div className="new-path-arrow">
            <NewPathArrow />
          </div>
        )}
      </Fragment>
    );
  });

  return <div className="items">{list}</div>;
}

export function displaySkills(skills, championId, ssr) {
  const list = skills.map((skillKey, index) => {
    const skillSlot = skillMapping(skillKey);
    return (
      <Fragment key={index}>
        <div className="skill-wrapper">
          <ChampionSkill championId={championId} skillSlot={skillSlot} ssr={ssr} />
          <div className="label">
            <span>{skillKey.toUpperCase()}</span>
          </div>
        </div>
        {index !== 2 && (
          <div className="new-path-arrow">
            <NewPathArrow />
          </div>
        )}
      </Fragment>
    );
  });

  return <div className="skill-path">{list}</div>;
}
