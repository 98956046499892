function capitalizeFirstLetter(str) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export { capitalizeFirstLetter, numberWithCommas };
