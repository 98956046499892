import React from "react";
import { TooltipContainer } from "@outplayed/tooltips";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getTierColor } from "lib/tierHelper";
import { numberWithCommas } from "lib/general-helper.js";

const ChampionCounterTooltip = (props) => {
  const { getChampionImgFromSprite } = getRiotAssetsContext();
  const { curChampionId, oppChampionId, datum } = props;

  // if (datum.delta) {
  //   return (
  //     <div className="tooltip-item tooltip-champion-counter">
  //       <div className="current-champion">
  //         {getChampionImgFromSprite(curChampionId, {size: 24})}
  //       </div>
  //       <div className="win-comparison">
  //         wins
  //         &nbsp;
  //         <span>{`${Math.abs(datum.delta)}%`}</span>
  //         &nbsp;
  //         {datum.delta >= 0
  //           ? <i style={{color: "#08a6ff"}}>MORE</i>
  //           : <i style={{color: "#ff4e50"}}>LESS</i>
  //         }
  //         &nbsp;
  //         {datum.type === "team" ? "with" : "vs"}
  //       </div>
  //       <div className="counter-champion">
  //         {getChampionImgFromSprite(oppChampionId, {size: 24})}
  //       </div>
  //       <div className="total-matches">
  //         <span>Matches:</span>
  //         &nbsp;
  //         {numberWithCommas(datum.matches)}
  //       </div>
  //     </div>
  //   )
  // }

  const curWinRate = 100 - datum.win_rate;

  return (
    <div className="tooltip-item tooltip-champion-counter">
      <div className="current-champion">{getChampionImgFromSprite(oppChampionId, { size: 24 })}</div>
      <div className="win-comparison">
        wins &nbsp;
        <span className={getTierColor(curWinRate)}>{curWinRate.toFixed(1)}%</span>
        &nbsp; vs
      </div>
      <div className="counter-champion">{getChampionImgFromSprite(curChampionId, { size: 24 })}</div>
      <div className="total-matches">
        <span>Matches:</span>
        &nbsp;
        {numberWithCommas(datum.matches)}
      </div>
    </div>
  );
};

const ChampionCounter = (props) => {
  const { getChampionImgFromSprite } = getRiotAssetsContext();
  const { data, dim, children } = props;
  const { curChampionId, oppChampionId, datum } = data;

  const img = children || getChampionImgFromSprite(oppChampionId, { size: dim || 30 });

  const tooltipInfo = <ChampionCounterTooltip curChampionId={curChampionId} oppChampionId={oppChampionId} datum={datum} />;

  return <TooltipContainer tooltipInfo={tooltipInfo}>{img}</TooltipContainer>;
};

export default ChampionCounter;
