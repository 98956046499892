import React from "react";
import classNames from "classnames";
import { useGlobal } from "reactn";
import { Link } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { getRoleImg, getRevApiRole, RoleS } from "@ugg/shared/utils/role-helpers";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { getChampionBuildUrl, getSpecialGameModeChampionBuildUrl } from "@ugg/shared/routes/app-routes";
import { useTierListPage } from "@ugg/shared/pages/tier-list-pages";
import { buildQueryParams } from "@ugg/shared/utils/url-query-params";
import { SPECIAL_GAME_MODES } from "@ugg/shared/utils/special-game-modes";

interface ChampionLinkInterface {
  className?: string;
  championId: number;
  queueType?: string;
  role?: string;
  dim?: number;
  showRoleIcon?: boolean;
  ImgWrapper?: ({ children }: { children: React.ReactNode }) => JSX.Element;
}

export function ChampionLink(props: ChampionLinkInterface) {
  const { className, championId, role, queueType, dim, showRoleIcon, ImgWrapper } = props;
  const [language] = useGlobal("language");
  const { getChampionName, getNormalizedChampionName, getChampionImgFromSprite } = getRiotAssetsContext();
  const tierListPage = useTierListPage();
  // URL name if is special game mode
  const { urlName } =
    Object.values(SPECIAL_GAME_MODES).find((mode) => mode.tierListPageName === tierListPage || mode.queueType === queueType) ||
    {};
  let params = useQueryString();

  const normalizedChampionName = getNormalizedChampionName(championId);
  let pathname = getChampionBuildUrl(normalizedChampionName, { language, ...(role && { role: getRevApiRole(role) }) });
  if (urlName) {
    if (role && role !== RoleS.NONE) {
      params = { ...params, role: getRevApiRole(role) };
    }
    pathname = getSpecialGameModeChampionBuildUrl(normalizedChampionName, urlName);
  } else {
    const urlSearchParams = new URLSearchParams({ ...params });
    urlSearchParams.delete("role");
    params = Object.fromEntries(urlSearchParams);
  }

  const Wrapper = ImgWrapper || React.Fragment;

  return (
    <Link
      className={classNames("group/champion-link flex items-center justify-center flex-1 w-full", className)}
      to={{
        pathname,
        search: buildQueryParams(params),
      }}
    >
      <Wrapper>
        <div className="relative px-[10px]">
          <div
            className="relative rounded-[3px] overflow-hidden [&>div]:absolute [&>div]:top-[50%] [&>div]:left-[50%] [&>div]:translate-x-[-50%] [&>div]:translate-y-[-50%] [&>div]:scale-[1.1]"
            style={{ width: dim, height: dim }}
          >
            {getChampionImgFromSprite(championId, { size: dim || 30 })}
          </div>
          {showRoleIcon && role && role !== RoleS.NONE && (
            <div className="role absolute right-[3px] bottom-[-4px] p-[2px] rounded-[50%] bg-[rgba(0,0,0,0.7)]">
              <img className="role-img" src={getRoleImg(role)} alt={role} width={10} />
            </div>
          )}
        </div>
      </Wrapper>
      <strong className="champion-name group-hover/champion-link:underline ml-[2px] flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
        {getChampionName(championId)}
      </strong>
    </Link>
  );
}
