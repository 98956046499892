import React from "react";
import { Switch, Route } from "react-router-dom";

import ChampionsPage from "./Champions/ChampionsPage/ChampionsPage";
import ChampionProfile from "components/Champions/ChampionProfile";
import { getSpecialUrlName } from "lib/specialGameMode";

const Champions = (props) => {
  let specialRoutes = [];
  for (const [index, val] of getSpecialUrlName().entries()) {
    specialRoutes.push(
      <Route key={index} path={`/lol/champions/${val}/:multi?/:champion`} render={(props) => <ChampionProfile {...props} />} />,
    );
  }

  return (
    <Switch>
      <Route path="/lol/champions/aram/:multi?/:champion" render={(props) => <ChampionProfile {...props} />} />
      {specialRoutes}
      <Route
        path={[
          "/lol/:language?/champions/:champion/:role-build",
          "/lol/:language?/champions/:multi?/:champion/build/:role",
          "/lol/:language?/champions/:multi?/:champion/build",
          "/lol/:language?/champions/:champion",
        ]}
        render={() => <ChampionProfile />}
      />
      <Route
        path={["/lol/champions", "/lol/champion-leaderboards", "/lol/champion-leaderboards/:champName"]}
        render={(props) => <ChampionsPage {...props} />}
      />
    </Switch>
  );
};

export default Champions;
